import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { useAddCompanyMutation, useListPlansQuery } from "common/redux/features/FirestoreDB";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify the company's name"),
  planId: yup.string().required("Please specify the company's plan"),
});

function CreateCompanyFormDialog() {
  const { t } = useTranslation("common");

  const { data: plans } = useListPlansQuery(undefined);

  const [open, setOpen] = useState(false);
  const initialValues = {
    name: "",
    planId: "",
  };

  const [addCompany] = useAddCompanyMutation();

  const onSubmit = async (values: { name: string; planId: string }) => {
    const newPlanId = plans?.find((plan) => plan !== null && plan.id === values.planId)?.id;
    if (newPlanId) {
      toast.promise(addCompany(values), {
        loading: t("common.toast.saving"),
        success: () => {
          setOpen(false);
          return t("common.toast.savedSuccessfully"); // TODO replace
        },
        error: t("common.toast.error"),
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h1>{t("admin.companies.header")}</h1>
      <Box sx={{ flexDirection: "row-reverse" }}>
        <Button variant="contained" onClick={handleClickOpen}>
          {t("admin.companies.createCompany")}
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>{t("admin.companies.createCompany")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("admin.companies.dialog")}</DialogContentText>
              <TextField
                autoFocus
                name="name"
                label={`${t("admin.common.labels.name")} *`}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <FormControl fullWidth variant="standard">
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <InputLabel id="companyId-label">{`${t("admin.common.labels.plan")} *`}</InputLabel>
                <Select
                  labelId="planId-label"
                  id="planId"
                  name="planId"
                  value={formik.values.planId}
                  onChange={formik.handleChange}
                  error={formik.touched.planId && Boolean(formik.errors.planId)}
                >
                  <MenuItem key="" value="">
                    <em>{t("admin.common.labels.plan")}</em>
                  </MenuItem>
                  {plans?.map((plan) => (
                    <MenuItem key={plan?.id} value={plan?.id}>
                      {plan?.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.planId && formik.errors.planId}</FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
              <Button type="submit">{t("common.buttons.create")}</Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
}

export default CreateCompanyFormDialog;
