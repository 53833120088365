import React, { useEffect } from "react";
import { Avatar, Box, IconButton, Link, Menu, MenuItem, Tooltip } from "@mui/material";
import { MenuContext } from "common/context/MenuContext";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { auth, logout } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useGetPersonQuery } from "common/redux/features/FirestoreDB";

function NavAvatar() {
  const { t } = useTranslation("common");
  const menu = React.useContext(MenuContext);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [user, _loading, _error] = useAuthState(auth);

  // fetch and set groups
  const [groups, setGroups] = React.useState([] as Array<string>);
  useEffect(() => {
    user?.getIdTokenResult().then((idTokenResult) => {
      const isMember = idTokenResult.claims["member"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isManager = idTokenResult.claims["manager"];
      const isAdmin = idTokenResult.claims["admin"];

      const localGroups = [] as Array<string>;
      if (isMember) localGroups.push("Members");
      if (isPractitioner) localGroups.push("Practitioners");
      if (isManager) localGroups.push("Managers");
      if (isAdmin) localGroups.push("Admins");

      setGroups(localGroups);
    });
  }, [user]);

  const { data: person } = useGetPersonQuery({
    id: user?.uid ?? "",
    collection: groups.includes("Practitioners") ? "practitioners" : "members",
  });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box marginLeft={4}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={person?.name} src={person?.avatar} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {menu.account?.map(
          (setting) =>
            (!setting.group || groups?.includes(setting.group)) && (
              <MenuItem key={setting.title} onClick={handleCloseUserMenu}>
                <Link underline="none" component={RouterLink} to={setting.to} color="text.primary">
                  {t(setting.title)}
                </Link>
              </MenuItem>
            )
        )}
        {user && (
          <MenuItem onClick={handleCloseUserMenu}>
            <Link underline="none" onClick={logout} color="text.primary">
              {t("common.buttons.signOut")}
            </Link>
            {/* <Typography textAlign="center">{setting.title}</Typography> */}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}

export default NavAvatar;
