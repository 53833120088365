import React from "react";
import Box from "@mui/material/Box";

function Container({
  children,
  ...rest
}: {
  children: React.ReactNode;
  // All other props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}) {
  return (
    <Box margin={"0 auto"} maxWidth={{ sm: 720, md: 1236 }} width={1}>
      <Box paddingX={2} paddingY={{ xs: 4, sm: 6, md: 8 }} {...rest}>
        {children}
      </Box>
    </Box>
  );
}

export default Container;
