import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import {
  useGetCompanyQuery,
  useGetMemberQuery,
  useGetPlanQuery,
} from "common/redux/features/FirestoreDB";

function MyPlanView() {
  const { t } = useTranslation("common");
  const [user, _loading, _error] = useAuthState(auth);

  const { data: member } = useGetMemberQuery(user?.uid ?? "");
  const { data: company } = useGetCompanyQuery(member?.companyId ?? "");
  const { data: plan } = useGetPlanQuery(company?.planId ?? "");

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h4" fontWeight={500}>
          {t("member.orgView.firstLine", {
            company: company?.name,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight={200}>
          {t("member.orgView.currentPlan", {
            plan: plan?.name,
            credits: plan?.credits,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default MyPlanView;
