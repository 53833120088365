import React from "react";
import { Container } from "common/components";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import {
  useGetCompanyQuery,
  useListPlansQuery,
  useSetCompanyMutation,
} from "common/redux/features/FirestoreDB";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Please enter a valid plan name")
    .max(50, "Please enter a valid plan name")
    .required("Please specify the plan's name"),
  planId: yup.string().required("Please specify the company's plan"),
});

function CompanyView() {
  const { t } = useTranslation("common");
  const { id } = useParams();

  const { data: company } = useGetCompanyQuery(id ?? "");
  const { data: plans } = useListPlansQuery(undefined);

  const [disabled, setDisabled] = React.useState(true);
  const initialValues = {
    name: company?.name ?? "",
    planId: company?.planId ?? "",
  };

  const onEdit = () => {
    setDisabled((ps) => !ps);
  };

  const [setCompany] = useSetCompanyMutation();

  const onSubmit = async (values: { name: string; planId: string }) => {
    const planId = plans?.find((plan) => plan !== null && plan.id === values.planId)?.id;
    if (company && company.id && planId) {
      toast.promise(setCompany({ id: company.id, values }), {
        loading: t("common.toast.saving"),
        success: () => {
          onEdit();
          return t("common.toast.memberUpdatedSuccessfully"); // TODO replace
        },
        error: t("common.toast.error"),
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>
              {t("admin.companies.company")}
              {id}
            </h1>
            <Box
              sx={{
                flexDirection: "row-reverse",
              }}
            >
              {disabled && (
                <Button variant="contained" onClick={onEdit}>
                  {t("common.buttons.edit")}
                </Button>
              )}
              {!disabled && (
                <ButtonGroup variant="contained">
                  <Button variant="contained" onClick={() => formik.handleSubmit()}>
                    {t("common.buttons.accept")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.resetForm();
                      onEdit();
                    }}
                  >
                    {t("common.buttons.cancel")}
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            disabled={disabled}
            label={t("admin.common.labels.name")}
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl disabled={disabled} fullWidth>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <InputLabel id="companyId-label">{`${t("admin.common.labels.plan")} *`}</InputLabel>
            <Select
              labelId="planId-label"
              id="planId"
              name="planId"
              value={formik.values.planId}
              onChange={formik.handleChange}
              error={formik.touched.planId && Boolean(formik.errors.planId)}
            >
              <MenuItem key="" value="">
                <em>{t("admin.common.labels.plan")}</em>
              </MenuItem>
              {plans?.map((plan) => (
                <MenuItem key={plan?.id} value={plan?.id}>
                  {plan?.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.planId && formik.errors.planId}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CompanyView;
