import React, { useEffect, useLayoutEffect, useState } from "react";
// layout
import {
  Box,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Calendar } from "newAPI";
import moment from "moment";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import { useGetMemberQuery, useListAppointmentsQuery } from "common/redux/features/FirestoreDB";
import AppointmentRow from "./components/AppointmentRow";

function AppointmentsView() {
  const { t } = useTranslation("common");
  const [calendar, setCalendar] = React.useState({} as Calendar);
  const [user, _loading, _error] = useAuthState(auth);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const [billable, setBillable] = useState(false);

  const { data: member } = useGetMemberQuery(user?.uid ?? "");

  const [skip, setSkip] = useState(true);
  useEffect(() => {
    if (member) setSkip(false);
  }, [member]);

  const { data: appointments } = useListAppointmentsQuery(
    [{ field: "companyId", op: "==", value: member?.companyId }],
    { skip }
  );

  useLayoutEffect(() => {
    // sort appointments
    const sorted =
      appointments?.slice().sort((a, b) => {
        if (a.datetime > b.datetime) {
          return -1;
        }
        if (a.datetime < b.datetime) {
          return 1;
        }
        return 0;
      }) ?? [];
    // transform them to a calendar
    const cal = {} as Calendar;
    for (const appointment of sorted) {
      if (billable && appointment.status == "CANCELADA") {
        continue;
      }
      const yearMonth = moment(appointment.datetime).format("YYYY - MMMM");
      if (!cal[yearMonth]) {
        cal[yearMonth] = [];
      }
      cal[yearMonth].push(appointment);
    }
    setCalendar(cal);
  }, [appointments, setCalendar, billable]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5" fontWeight={500}>
          {t("member.orgView.appointments")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box display="flex" alignItems={"center"} justifyContent={isSm ? "space-between" : "flex-end"}>
          <Typography variant="h5" fontWeight={500}>
            Mostrar solo facturables
          </Typography>
          <Switch
            name="verified"
            checked={billable}
            onChange={() => setBillable((prevState) => !prevState)}
            inputProps={{
              "aria-label": "controlled",
            }}
          />
        </Box>
      </Grid>
      {Object.entries(calendar).length == 0 && (
        <Grid item container spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography>No hay citas.</Typography>
          </Grid>
        </Grid>
      )}
      {Object.entries(calendar).map(([yearMonth, events]) => (
        // the following line changes the margin-bottom param for all containers except for the last one
        <Grid item container spacing={2} xs={12} key={yearMonth}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={500}>
              {yearMonth}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }} aria-label="simple table">
                <TableHead sx={{ bgcolor: "primary.main" }}>
                  <TableRow>
                    {(
                      t("member.orgView.appointmentsTableHeaders", {
                        returnObjects: true,
                      }) as Array<string>
                    ).map((item) => (
                      <TableCell key={item}>
                        <Typography
                          color={"primary.light"}
                          variant={"caption"}
                          fontWeight={700}
                          sx={{
                            textTransform: "uppercase",
                          }}
                        >
                          {item}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events &&
                    events?.map(
                      (appointment) =>
                        appointment !== null && (
                          <AppointmentRow appointment={appointment} key={appointment.id} />
                        )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default AppointmentsView;
