import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Member } from "newAPI";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function DeletedMemberRow({ member }: { member: Member }) {
  const { t } = useTranslation("common");
  const theme = useTheme();

  return (
    <TableRow
      hover
      key={member.id}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row" sx={{ pt: 1, pb: 1 }}>
        <List sx={{ p: 0, m: 0 }}>
          <Tooltip title={t("member.appointmentsView.seePractitionerTooltip")}>
            <ListItemButton
              component={RouterLink}
              to={"/user/org/members/" + member.id}
              sx={{ p: 0, m: 0 }}
            >
              <ListItem sx={{ p: 0, m: 0 }}>
                <ListItemAvatar>
                  <Avatar src={member?.avatar ?? ""} />
                </ListItemAvatar>
                <ListItemText primary={member?.name} secondary={member?.email} />
              </ListItem>
            </ListItemButton>
          </Tooltip>
        </List>
      </TableCell>
      <TableCell>
        <Typography>{member.credits}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant={"caption"}
          fontWeight={700}
          sx={{
            bgcolor: alpha(theme.palette.error.light, 0.1),
            color: theme.palette.error.dark,
            paddingX: 1.5,
            paddingY: 0.5,
            borderRadius: 4,
            display: "inline",
          }}
        >
          {"Eliminado"}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default DeletedMemberRow;
