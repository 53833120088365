import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Box, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { updatePassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";

const validationSchema = yup.object({
  currentPassword: yup.string().required("Please specify your password"),
  newPassword: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
  repeatPassword: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
});

function ChangePassword() {
  const [user, _loading, _error] = useAuthState(auth);
  const { t } = useTranslation("common");
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    repeatPassword: "",
  };

  const onSubmit = (values: { currentPassword: string; newPassword: string }) => {
    if (!user) return;
    // TODO reauthenticate user https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
    toast.promise(updatePassword(user, values.newPassword), {
      loading: t("common.toast.updating"),
      success: t("common.toast.passwordUpdatedSuccessfully"),
      error: t("common.toast.error"),
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <>
      <Typography variant="h6" gutterBottom fontWeight={700}>
        {t("common.account.security.header")}
      </Typography>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
              {t("common.account.security.currentPassword")}
            </Typography>
            <TextField
              variant="outlined"
              name={"currentPassword"}
              type={"password"}
              fullWidth
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
              helperText={formik.touched.currentPassword && formik.errors.currentPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
              {t("common.account.security.newPassword")}
            </Typography>
            <TextField
              variant="outlined"
              name={"newPassword"}
              type={"password"}
              fullWidth
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
              {t("common.account.security.repeatPassword")}
            </Typography>
            <TextField
              variant="outlined"
              name={"repeatPassword"}
              type={"password"}
              fullWidth
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
              helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
            />
          </Grid>

          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{
                xs: "column",
                sm: "row-reverse",
              }}
              alignItems={{
                xs: "stretched",
                sm: "center",
              }}
              justifyContent={"space-between"}
              width={1}
              margin={"0 auto"}
            >
              <Button size={"large"} variant={"contained"} type={"submit"}>
                {t("common.buttons.save")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default ChangePassword;
