import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import Settings from "@mui/icons-material/Psychology";
import { Calendar } from "newAPI";
import moment from "moment";
import {
  useGetMemberQuery,
  useListAppointmentsQuery,
  useSetMemberMutation,
} from "common/redux/features/FirestoreDB";
import EditAvatar from "./EditAvatar";
import AppointmentsViewRow from "./AppointmentsView/AppointmentsViewRow";

const validationSchema = yup.object({
  credits: yup
    .number()
    .integer()
    .moreThan(-1, "Please specify a valid credits configuration (>=0)")
    .required("Please specify the credits configuration"),
});

function MemberView() {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  const { data: member } = useGetMemberQuery(id ?? "");

  const [skip, setSkip] = useState(true);
  useEffect(() => {
    if (member) setSkip(false);
  }, [member]);

  const { data: appointments } = useListAppointmentsQuery(
    [
      { field: "companyId", op: "==", value: member?.companyId },
      { field: "memberId", op: "==", value: id },
    ],
    { skip }
  );

  const [calendar, setCalendar] = React.useState({} as Calendar);

  useLayoutEffect(() => {
    // sort appointments
    const sorted =
      appointments?.slice().sort((a, b) => {
        if (a.datetime > b.datetime) {
          return -1;
        }
        if (a.datetime < b.datetime) {
          return 1;
        }
        return 0;
      }) ?? [];
    // transform them to a calendar
    const cal = {} as Calendar;
    for (const appointment of sorted) {
      const yearMonth = moment(appointment.datetime).format("YYYY - MMMM");
      if (!cal[yearMonth]) {
        cal[yearMonth] = [];
      }
      cal[yearMonth].push(appointment);
    }
    setCalendar(cal);
  }, [appointments, setCalendar]);

  const [disabled, setDisabled] = React.useState(true);
  const initialValues = {
    name: member?.name ?? "",
    credits: member?.credits ?? 0,
  };

  const onEdit = () => {
    setDisabled((ps) => !ps);
  };

  const [setMember] = useSetMemberMutation();

  const onSubmit = async (values: { name: string; credits: number }) => {
    if (member && member.id) {
      toast.promise(setMember({ id: member.id, values }), {
        loading: t("common.toast.saving"),
        success: () => {
          onEdit();
          return t("common.toast.memberUpdatedSuccessfully");
        },
        error: t("common.toast.error"),
      });
    } else {
      toast("User doesn't exist in DB. In needs to be created manually!", {
        icon: <Settings />,
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            flexDirection={{
              xs: "column",
              sm: "row-reverse",
            }}
            alignItems={{
              xs: "stretched",
              sm: "center",
            }}
            justifyContent={"space-between"}
            width={1}
          >
            <Box sx={{ width: !isSm ? "auto" : 1 }}>
              {disabled && (
                <Button variant="contained" onClick={onEdit} fullWidth={isMd}>
                  {t("common.buttons.edit")}
                </Button>
              )}
              {!disabled && (
                <ButtonGroup variant="contained" fullWidth={isMd}>
                  <Button variant="contained" onClick={() => formik.handleSubmit()}>
                    {t("common.buttons.accept")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.resetForm();
                      onEdit();
                    }}
                  >
                    {t("common.buttons.cancel")}
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        {member && <EditAvatar member={member} />}
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          disabled={disabled}
          label={t("admin.common.labels.name")}
          fullWidth
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          disabled
          label={t("admin.common.labels.email")}
          fullWidth
          name="email"
          type="email"
          value={member?.email ?? ""}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          disabled={disabled}
          label={t("admin.common.labels.credits")}
          fullWidth
          name="credits"
          type="number"
          value={formik.values.credits}
          onChange={formik.handleChange}
          error={formik.touched.credits && Boolean(formik.errors.credits)}
          helperText={formik.touched.credits && formik.errors.credits}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4" fontWeight={500}>
          {t("member.memberView.appointmentsHeader")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        {Object.entries(calendar).length == 0 && <Typography my={2}>No hay citas.</Typography>}
        {Object.entries(calendar).map(([yearMonth, events]) => (
          // the following line changes the margin-bottom param for all containers except for the last one
          <Box key={yearMonth}>
            <Typography my={2} variant="h5" fontWeight={500}>
              {yearMonth}
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }} aria-label="simple table">
                <TableHead sx={{ bgcolor: "primary.main" }}>
                  <TableRow>
                    {(
                      t("member.orgView.appointmentsTableHeaders", {
                        returnObjects: true,
                      }) as Array<string>
                    ).map((item) => (
                      <TableCell key={item}>
                        <Typography
                          color={"primary.light"}
                          variant={"caption"}
                          fontWeight={700}
                          sx={{
                            textTransform: "uppercase",
                          }}
                        >
                          {item}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events &&
                    events?.map(
                      (appointment) =>
                        appointment !== null && (
                          <AppointmentsViewRow appointment={appointment} key={appointment.id} />
                        )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}

export default MemberView;
