import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Link, Typography } from "@mui/material";
import { MenuContext } from "common/context/MenuContext";
import { ContactButton } from "common/components";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import Logo from "../common/Logo";
import NavAvatar from "./NavAvatar";
import NavIcon from "./NavIcon";

function Topbar({ onSidebarOpen }: { onSidebarOpen: () => void }) {
  const { t } = useTranslation("common");
  const menu = React.useContext(MenuContext);

  const [user, _loading, _error] = useAuthState(auth);

  const [groups, setGroups] = React.useState<Array<string>>([]);
  useEffect(() => {
    user?.getIdTokenResult().then((idTokenResult) => {
      const isMember = idTokenResult.claims["member"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isManager = idTokenResult.claims["manager"];
      const isAdmin = idTokenResult.claims["admin"];

      const localGroups = [] as Array<string>;
      if (isMember) localGroups.push("Members");
      if (isPractitioner) localGroups.push("Practitioners");
      if (isManager) localGroups.push("Managers");
      if (isAdmin) localGroups.push("Admins");

      setGroups(localGroups);
    });
  }, [user]);

  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={1}>
      <Logo />
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          paddingY: 1,
        }}
        alignItems={"center"}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
          }}
          alignItems={"center"}
        >
          {menu.pages.map(
            (item, i) =>
              ((!groups && !item.group) || (item.group && groups?.includes(item.group))) && (
                <Box marginLeft={2} key={i}>
                  <Link underline="none" component={RouterLink} to={item.to} color="text.primary">
                    <Typography
                      variant="overline"
                      fontWeight={item.to === window?.location?.pathname ? 700 : 400}
                    >
                      {t(item.title)}
                    </Typography>
                  </Link>
                </Box>
              )
          )}
        </Box>
        {user && <NavAvatar />}
        {!user && (
          <>
            <ContactButton marginLeft={2}>{t("common.buttons.contact")}</ContactButton>
            <Box marginLeft={2}>
              <Button
                variant="outlined"
                color="primary"
                component={RouterLink}
                to="/"
                size="small"
              >
                {t("common.buttons.access")}
              </Button>
            </Box>
          </>
        )}
      </Box>
      <NavIcon onSideBarOpen={onSidebarOpen} />
    </Box>
  );
}

export default Topbar;
