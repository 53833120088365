import React, { useEffect } from "react";
import { auth, logout } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

function Logout() {
  const [user, _loading, _error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) logout();
    navigate("/");
  }, [navigate, user]);
  return <></>;
}

export default Logout;
