import React from "react";
// layout
import { Box, Divider } from "@mui/material";

import { Route, Routes } from "react-router-dom";
import { NotFoundView } from "common/components";
import { AccountPage, AttributeName, ChangePassword, Plan } from "./components";

function AccountView() {
  return (
    <Box bgcolor={"primary.light"}>
      <AccountPage>
        <Routes>
          <Route index element={<AttributeName />} />
          <Route path="security" element={<ChangePassword />} />
          <Route path="plan" element={<Plan />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </AccountPage>
      <Divider />
    </Box>
  );
}

export default AccountView;
