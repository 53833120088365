import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { MenuContext } from "common/context/MenuContext";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function ContactDialog({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  const { t } = useTranslation("common");
  const menu = React.useContext(MenuContext);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("common.contactForm.youAre")}</DialogTitle>
      <DialogContent>
        <List sx={{ pt: 0 }}>
          {menu.contact?.map((contactOption) => (
            <Link
              key={contactOption.title + contactOption.body}
              component={RouterLink}
              to={contactOption.to}
              onClick={handleClose}
              underline={"none"}
            >
              <ListItem button onClick={() => handleClose()}>
                <ListItemAvatar>
                  <Avatar>{contactOption.avatar}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={t(contactOption.title)} secondary={t(contactOption.body)} />
              </ListItem>
            </Link>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContactDialog;
