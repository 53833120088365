import React, { useEffect } from "react";
import { Box, Button, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MenuContext } from "common/context/MenuContext";
import { ContactButton } from "common/components";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import Logo from "../common/Logo";

function Footer() {
  const { t } = useTranslation("common");
  const menu = React.useContext(MenuContext);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  const [user, _loading, _error] = useAuthState(auth);

  const [groups, setGroups] = React.useState<Array<string>>([]);
  useEffect(() => {
    user?.getIdTokenResult().then((idTokenResult) => {
      const isMember = idTokenResult.claims["member"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isManager = idTokenResult.claims["manager"];
      const isAdmin = idTokenResult.claims["admin"];

      const localGroups = [] as Array<string>;
      if (isMember) localGroups.push("Members");
      if (isPractitioner) localGroups.push("Practitioners");
      if (isManager) localGroups.push("Managers");
      if (isAdmin) localGroups.push("Admins");

      setGroups(localGroups);
    });
  }, [user]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={3}>
        <Logo xs={80} md={80} />
        <Typography
          marginTop={2}
          variant="caption"
          component="p"
          color="text.secondary"
          sx={{ fontWeight: 400 }}
          align={"left"}
        >
          {t("common.emergencyNotice")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={3}></Grid>
      <Grid item xs={12} sm={12} md={3}>
        {menu.pages.map(
          (item, i) =>
            (!item.group || groups?.includes(item.group)) && (
              <Box key={i}>
                <Link
                  underline="none"
                  component={RouterLink}
                  to={item.to}
                  color="text.primary"
                  variant={"overline"}
                >
                  {t(item.title)}
                </Link>
              </Box>
            )
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        {menu.footerColumn2.map((item, i) => (
          <Box key={i}>
            <Link
              underline="none"
              component={RouterLink}
              to={item.to}
              color="text.primary"
              variant={"overline"}
            >
              {t(item.title)}
            </Link>
          </Box>
        ))}
        {menu.contact?.map(
          (item, i) =>
            item.footer && (
              <Box key={i}>
                <Link
                  underline="none"
                  component={RouterLink}
                  to={item.to}
                  color="text.primary"
                  variant={"overline"}
                >
                  {t(item.footer)}
                </Link>
              </Box>
            )
        )}
      </Grid>
      <Grid item md={12} style={{ width: "100%" }}>
        <Grid container>
          {/* <Box
          marginTop={{ sm: 2 }}
          display={"flex"}
          justifyContent={{ xs: "flex-start", sm: "space-between" }}
          alignItems={{ xs: "left", sm: "center" }}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        > */}
          {/* privacy */}
          <Grid item xs={12} sm={12} md={!user ? 4 : 6}>
            <Box>
              <Link
                underline="none"
                component={RouterLink}
                to={menu.terms.privacy.to}
                color="text.primary"
                variant={"overline"}
              >
                {t(menu.terms.privacy.title)}
              </Link>
            </Box>
          </Grid>
          {/* legal */}
          <Grid item xs={12} sm={12} md={!user ? 4 : 6}>
            <Box>
              <Link
                underline="none"
                component={RouterLink}
                to={menu.terms.legal.to}
                color="text.primary"
                variant={"overline"}
              >
                {t(menu.terms.legal.title)}
              </Link>
            </Box>
          </Grid>
          {!user && (
            <Grid item xs={12} sm={12} md={4}>
              <Box
                display="flex"
                justifyContent={{
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-end",
                }}
                alignItems={{
                  xs: "left",
                  sm: "left",
                  md: "center",
                }}
                width={1}
                flexDirection={{
                  xs: "column",
                  sm: "column",
                  md: "row",
                }}
              >
                <ContactButton marginRight={2}>{t("common.buttons.contact")}</ContactButton>
                <Box marginRight={2} marginTop={isMd ? 2 : 0}>
                  <Button
                    variant="outlined"
                    color="primary"
                    component={RouterLink}
                    to="/"
                    size="small"
                    fullWidth={isMd}
                  >
                    {t("common.buttons.access")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>

        {/* </Box> */}
      </Grid>
      <Grid item md={12} marginTop={2}>
        <Typography align={"center"} color={"grey"} fontWeight={500} fontSize={15} gutterBottom>
          {t("common.copyrightNotice")}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
