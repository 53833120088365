import React from "react";
import { PaletteColor, PaletteOptions, alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "common/util/formatNumber";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function AppWidgetSummary({
  title,
  total,
  icon,
  color,
  ...other
}: {
  title: string;
  total: number;
  icon: React.ReactNode;
  color: keyof PaletteOptions;
}) {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: "center",
        color: (theme) => (theme.palette[color] as PaletteColor).dark,
        bgcolor: (theme) => (theme.palette[color] as PaletteColor).light,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => (theme.palette[color] as PaletteColor).dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(
              (theme.palette[color] as PaletteColor).dark,
              0
            )} 0%, ${alpha((theme.palette[color] as PaletteColor).dark, 0.24)} 100%)`,
        }}
      >
        {icon}
      </StyledIcon>

      <Typography variant="h3">{fShortenNumber(total)}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}
