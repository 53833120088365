import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { Container } from "common/components";
import illustration from "common/img/wfh/wfh_5.svg";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function NotFoundView() {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      position={"relative"}
      minHeight={"calc(100vh - 247px)"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={1}
      marginTop={-12}
      paddingTop={12}
    >
      <Container>
        <Grid container>
          <Grid item container alignItems={"center"} justifyContent={"center"} xs={12} md={6}>
            <Box>
              <Typography
                variant="h1"
                component={"h1"}
                align={isMd ? "left" : "center"}
                sx={{ fontWeight: 700 }}
              >
                {t("common.notFound.404")}
              </Typography>
              <Typography
                variant="h6"
                component="p"
                color="text.secondary"
                align={isMd ? "left" : "center"}
              >
                {t("common.notFound.firstLine")}
                <br />
                {t("common.notFound.secondLine")}
                <Link component={RouterLink} to={"/contact"} underline="none">
                  {t("common.notFound.letUsKnow")}
                </Link>
              </Typography>
              <Box
                marginTop={4}
                display={"flex"}
                justifyContent={{
                  xs: "center",
                  md: "flex-start",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={RouterLink}
                  to={"/"}
                >
                  {t("common.notFound.backHomeButton")}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item container justifyContent={"center"} xs={12} md={6}>
            <Box height={1} width={1} maxWidth={500}>
              <Box
                component={"img"}
                src={illustration}
                width={1}
                height={1}
                sx={{
                  filter: theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default NotFoundView;
