import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { auth } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useGetPractitionerQuery,
  useSetPractitionerMutation,
} from "common/redux/features/FirestoreDB";
import PractitionerAvatarUploader from "./PractitionerAvatarUploader";

const tags = [
  "Aceptación y compromiso",
  "Breve",
  "Coaching",
  "Cognitivo-conductual",
  "Conductual",
  "Constructivista",
  "Dinámica",
  "EMDR",
  "Gestalt",
  "Humanista",
  "Integradora",
  "Mindfulness",
  "Positiva",
  "Psico-oncología",
  "Psicodinámica",
  "Psicopedagogía",
  "Sexual",
  "Sistémica",
  "",
];

const validationSchema = yup.object({
  name: yup.string().trim().max(50, "Too long. Please enter a valid name"),
  email: yup.string().email().required(),
  title: yup.string().trim().max(50, "Too long. Please enter a valid title"),
  summary: yup.string().trim().max(280, "Too long. Please enter a valid summary"),
  specialities: yup.array().of(yup.string()),
  description: yup.string().trim().max(3500, "Too long. Please enter a valid description"),
});

function DynamoSection({
  id,
  disabled,
  onEdit,
}: {
  id: string;
  disabled: boolean;
  onEdit: () => void;
}) {
  const { t } = useTranslation("common");
  const [user, _loading, _error] = useAuthState(auth);

  const { data: practitioner } = useGetPractitionerQuery(id ?? "");

  const initialValues = {
    name: practitioner?.name ?? "",
    email: practitioner?.email ?? "",
    verified: practitioner?.verified ?? false,
    title: practitioner?.title ?? "",
    summary: practitioner?.summary ?? "",
    specialities: practitioner?.specialities ?? [],
    description: practitioner?.description ?? "",
  };
  const [setPractitioner] = useSetPractitionerMutation();

  const onSubmit = async (values: {
    name: string;
    email: string;
    verified: boolean;
    title: string;
    summary: string;
    specialities: string[];
    description: string;
  }) => {
    if (practitioner) {
      toast.promise(setPractitioner({ id: practitioner.id, values }), {
        loading: t("common.toast.saving"),
        success: () => {
          onEdit();
          return t("common.toast.practitionerUpdatedSuccessfully");
        },
        error: t("common.toast.error"),
      });
    } else {
      toast.error("Error: practitioner not in DB");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>
            {t("admin.practitioners.practitioner")}
            {id}
          </h1>
          <Box
            sx={{
              flexDirection: "row-reverse",
            }}
          >
            {disabled && (
              <Button variant="contained" onClick={onEdit}>
                {t("common.buttons.edit")}
              </Button>
            )}
            {!disabled && (
              <ButtonGroup variant="contained">
                <Button variant="contained" onClick={() => formik.handleSubmit()}>
                  {t("common.buttons.accept")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    formik.resetForm();
                    onEdit();
                  }}
                >
                  {t("common.buttons.cancel")}
                </Button>
              </ButtonGroup>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        {practitioner && (
          <PractitionerAvatarUploader practitioner={practitioner} disabled={disabled} />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="verified"
                checked={formik.values.verified}
                onChange={formik.handleChange}
                inputProps={{
                  "aria-label": "controlled",
                }}
              />
            }
            label="Especialista verificado"
            disabled={disabled}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          disabled={disabled}
          label={t("admin.common.labels.name")}
          fullWidth
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          disabled={disabled}
          label={t("admin.common.labels.email")}
          fullWidth
          name="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          disabled={disabled}
          label={t("admin.common.labels.title")}
          fullWidth
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Autocomplete
          multiple
          popupIcon={""}
          id={"specialities"}
          options={tags}
          disabled={disabled}
          readOnly={disabled}
          value={formik.values.specialities}
          onChange={(e, value) => {
            formik.setFieldValue(
              "specialities",
              value !== null ? value : initialValues.specialities
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("admin.common.labels.specialities")}
              name={"specialities"}
              error={formik.touched.specialities && Boolean(formik.errors.specialities)}
              helperText={formik.touched.specialities && formik.errors.specialities}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          disabled={disabled}
          label={t("admin.common.labels.summary")}
          fullWidth
          name="summary"
          value={formik.values.summary}
          onChange={formik.handleChange}
          error={formik.touched.summary && Boolean(formik.errors.summary)}
          helperText={formik.touched.summary && formik.errors.summary}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          disabled={disabled}
          label={t("admin.common.labels.description")}
          fullWidth
          multiline
          minRows={5}
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
      </Grid>
    </>
  );
}

export default DynamoSection;
