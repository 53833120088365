import React from "react";
import { Container } from "common/components";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import AppConversionRates from "./components/AppConversionRates";
import AppWebsiteVisits from "./components/AppWebsiteVisits";
import AppWidgetSummary from "./components/AppWidgetSummary";

function MetricsView() {
  const theme = useTheme();

  return (
    <Box bgcolor={"primary.light"}>
      <Container mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <AppWidgetSummary
              title="Happines Index"
              total={714000}
              icon=<InsertEmoticonIcon />
              color="warning"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppWidgetSummary
              title="employee Net Promoter Score"
              total={714000}
              icon=<Diversity1Icon />
              color="info"
            />
          </Grid>
          <Grid item xs={12}>
            <AppWebsiteVisits
              title="Historial de métricas"
              subheader="(+43%) que el año pasado"
              chartLabels={[
                "01/01/2022",
                "02/01/2022",
                "03/01/2022",
                "04/01/2022",
                "05/01/2022",
                "06/01/2022",
                "07/01/2022",
                "08/01/2022",
                "09/01/2022",
                "10/01/2022",
                "11/01/2022",
              ]}
              chartData={[
                {
                  name: "Happiness Index",
                  type: "line",
                  fill: "solid",
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: "employee Net Promoter Score",
                  type: "line",
                  fill: "solid",
                  data: [44, 55, 41, 67, -22, 43, 21, 41, 56, 27, 43],
                },
                // {
                //   name: "employee Net Promoter Score",
                //   type: "area",
                //   fill: "gradient",
                //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                // },
                // {
                //   name: "Team C",
                //   type: "column",
                //   fill: "solid",
                //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                // },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppConversionRates
              title="Happiness Index por equipos"
              subheader="(+43%) que el año pasado"
              chartData={[
                { label: "Recursos Humanos", value: 400 },
                { label: "Finanzas", value: 430 },
                { label: "Marketing", value: 448 },
                { label: "Ventas", value: 470 },
                { label: "Operaciones", value: 540 },
                { label: "Tecnología de la Información (TI)", value: 580 },
                { label: "Desarrollo de Productos", value: 690 },
                { label: "Servicio al Cliente", value: 1100 },
                { label: "Comunicación Corporativa", value: 1200 },
                { label: "Legal", value: 1380 },
              ]}
              colors={[theme.palette.warning.main]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppConversionRates
              title="employee Net Promoter Score por equipos"
              subheader="(+43%) que el año pasado"
              chartData={[
                { label: "Recursos Humanos", value: 400 },
                { label: "Finanzas", value: 430 },
                { label: "Marketing", value: 448 },
                { label: "Ventas", value: 470 },
                { label: "Operaciones", value: 540 },
                { label: "Tecnología de la Información (TI)", value: 580 },
                { label: "Desarrollo de Productos", value: 690 },
                { label: "Servicio al Cliente", value: 1100 },
                { label: "Comunicación Corporativa", value: 1200 },
                { label: "Legal", value: 1380 },
              ]}
              colors={[theme.palette.info.main]}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default MetricsView;
