import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuContext } from "common/context/MenuContext";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth, logout } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useGetMemberQuery } from "common/redux/features/FirestoreDB";

function SideAvatar({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation("common");
  const [user, _loading, _error] = useAuthState(auth);
  const menu = React.useContext(MenuContext);

  const { data: member } = useGetMemberQuery(user?.uid ?? "");

  // set groups
  const [groups, setGroups] = React.useState([] as Array<string>);
  useEffect(() => {
    user?.getIdTokenResult().then((idTokenResult) => {
      const isMember = idTokenResult.claims["member"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isManager = idTokenResult.claims["manager"];
      const isAdmin = idTokenResult.claims["admin"];

      const localGroups = [] as Array<string>;
      if (isMember) localGroups.push("Members");
      if (isPractitioner) localGroups.push("Practitioners");
      if (isManager) localGroups.push("Managers");
      if (isAdmin) localGroups.push("Admins");

      setGroups(localGroups);
    });
  }, [user]);

  return (
    <Box marginTop={1}>
      <Accordion disableGutters elevation={0} sx={{ backgroundColor: "transparent" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: 0 }}
        >
          <Grid container>
            <Grid item xs={3}>
              <Avatar alt={member?.name ?? ""} src={member?.avatar ?? ""} />
            </Grid>
            <Grid item xs={9} display="flex" alignItems="center">
              <Typography fontWeight={400} color={"text.primary"}>
                {member?.name}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: 6 }}>
          <Grid container spacing={1}>
            {menu.account?.map(
              (item, i) =>
                (!item.group || groups?.includes(item.group)) && (
                  <Grid marginTop={1} item key={i} xs={12}>
                    <Link
                      underline="none"
                      component={RouterLink}
                      to={item.to}
                      onClick={onClose}
                      color="text.primary"
                    >
                      {t(item.title)}
                    </Link>
                  </Grid>
                )
            )}
            {user && (
              <Grid marginTop={1} item xs={12}>
                <Link underline="none" onClick={logout} color="text.primary">
                  {t("common.buttons.signOut")}
                </Link>
                {/* <Typography textAlign="center">{setting.title}</Typography> */}
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default SideAvatar;
