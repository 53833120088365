import React from "react";
import { Container } from "common/components";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link as RouterLink } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useListPlansQuery } from "common/redux/features/FirestoreDB";
import CreatePlanFormDialog from "./components/CreatePlanFormDialog";

const headerNames = ["ID", "Name", "Board", "Questions", "Indexes", "Credits", "Actions"];

const PlansView = () => {
  const { data: plans } = useListPlansQuery(undefined);

  return (
    <Container>
      <CreatePlanFormDialog />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerNames.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {plans !== undefined &&
              plans !== null &&
              plans.map(
                (plan) =>
                  plan && (
                    <TableRow hover key={plan.id}>
                      <TableCell>{plan.id}</TableCell>
                      <TableCell>{plan.name}</TableCell>
                      <TableCell>{plan.board ? <DoneIcon /> : <ClearIcon />}</TableCell>
                      <TableCell>{plan.questions ? <DoneIcon /> : <ClearIcon />}</TableCell>
                      <TableCell>{plan.indexes ? <DoneIcon /> : <ClearIcon />}</TableCell>
                      <TableCell>{plan.credits}</TableCell>
                      <TableCell>
                        <IconButton component={RouterLink} to={"/admin/plans/" + plan.id}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PlansView;
