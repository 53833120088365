import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppAuth from "apps/auth/AppAuth";
import { NotFoundView } from "common/components";

import { Toaster } from "react-hot-toast";
import "@fontsource/inter";

import AOS from "aos";
import "aos/dist/aos.css";
import AppPractitioner from "apps/practitioner/AppPractitioner";

import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
import ScrollToTop from "common/components/ScrollToTop/ScrollToTop";
import VerifyEmail from "apps/userManagement/VerifyEmail/VerifyEmail";
import AppAdmin from "./apps/admin/AppAdmin";
import AppUser from "./apps/user/AppUser";
moment.locale("es");

function App() {
  React.useLayoutEffect(() => {
    // AOS
    AOS.init();
    AOS.refresh();
  });

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/*" element={<AppAuth />} />
          <Route path="/verifyemail/*" element={<VerifyEmail />} />
          <Route path="/user/*" element={<AppUser />} />
          <Route path="/admin/*" element={<AppAdmin />} />
          <Route path="/practitioner/*" element={<AppPractitioner />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
        <Toaster position="bottom-right" reverseOrder={false} />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
