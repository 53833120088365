import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { useAddPlanMutation } from "common/redux/features/FirestoreDB";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify the plan's name"),
  board: yup.boolean(),
  questions: yup.boolean(),
  indexes: yup.boolean(),
  credits: yup
    .number()
    .integer()
    .moreThan(-1, "Please specify a valid credits configuration (>=0)")
    .required("Please specify the credits configuration"),
});

function CreatePlanFormDialog() {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const initialValues = {
    name: "",
    board: false,
    questions: false,
    indexes: false,
    credits: 0,
  };

  const [addPlan] = useAddPlanMutation();

  const onSubmit = async (values: {
    name: string;
    board: boolean;
    questions: boolean;
    indexes: boolean;
    credits: number;
  }) => {
    toast.promise(addPlan(values), {
      loading: t("common.toast.saving"),
      success: () => {
        setOpen(false);
        return t("common.toast.savedSuccessfully"); // TODO replace
      },
      error: t("common.toast.error"),
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h1>{t("admin.plans.header")}</h1>
      <Box sx={{ flexDirection: "row-reverse" }}>
        <Button variant="contained" onClick={handleClickOpen}>
          {t("admin.plans.createPlan")}
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>{t("admin.plans.createPlan")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("admin.plans.dialog")}</DialogContentText>
              <TextField
                autoFocus
                name="name"
                label={`${t("admin.common.labels.name")} *`}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="board"
                      checked={formik.values.board}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={`${t("admin.common.labels.board")} *`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="questions"
                      checked={formik.values.questions}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={`${t("admin.common.labels.questions")} *`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="indexes"
                      checked={formik.values.indexes}
                      onChange={formik.handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={`${t("admin.common.labels.indexes")} *`}
                />
              </FormGroup>
              <TextField
                name="credits"
                label={`${t("admin.common.labels.credits")} *`}
                type="number"
                fullWidth
                variant="standard"
                value={formik.values.credits}
                onChange={formik.handleChange}
                error={formik.touched.credits && Boolean(formik.errors.credits)}
                helperText={formik.touched.credits && formik.errors.credits}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
              <Button type="submit">{t("common.buttons.create")}</Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
}

export default CreatePlanFormDialog;
