import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./views/Login";
// import Register from "./views/Register";
import Logout from "./views/Logout";
import Reset from "./views/Reset";
import UserManagement from "./views/UserManagement";

function AppAuth() {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/reset" element={<Reset />} />
      {/* <Route path="/register" element={<Register />} /> */}
      <Route path="/usermgmt" element={<UserManagement />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}
export default AppAuth;
