import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useAdminCreatePractitionerMutation } from "common/redux/features/FirestoreDB";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify the user's name"),
  email: yup
    .string()
    .trim()
    .min(2, "Please enter a valid email")
    .max(50, "Please enter a valid email")
    .required("Please specify the user's email"),
});

const CreatePractitionerFormDialog = () => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const initialValues = {
    name: "",
    email: "",
  };

  const [adminCreatePractitioner] = useAdminCreatePractitionerMutation();

  const onSubmit = (values: { name: string; email: string }) => {
    toast.promise(adminCreatePractitioner(values), {
      loading: t("common.toast.creating"),
      success: () => {
        setOpen(false);
        return t("common.toast.practitionerCreatedSuccessfully");
      },
      error: t("common.toast.error"),
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h1>{t("admin.practitioners.header")}</h1>

      <Box sx={{ flexDirection: "row-reverse" }}>
        <Button variant="contained" onClick={handleClickOpen}>
          {t("admin.practitioners.createPractitioner")}
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>{t("admin.practitioners.createPractitioner")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("admin.practitioners.dialog")}</DialogContentText>
              <TextField
                autoFocus
                name="name"
                label={`${t("admin.common.labels.name")} *`}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                name="email"
                label={`${t("admin.common.labels.email")} *`}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
              <Button type="submit">{t("common.buttons.create")}</Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

export default CreatePractitionerFormDialog;
