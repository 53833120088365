import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Question } from "newAPI";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  useAddAnswerMutation,
  useGetAnswerQuery,
  useGetMemberQuery,
  useSetAnswerMutation,
} from "common/redux/features/FirestoreDB";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";

function QuestionRow({ question }: { question: Question }) {
  const [user, _loading, _error] = useAuthState(auth);

  const { data: member } = useGetMemberQuery(user?.uid ?? "");
  const { data: answer } = useGetAnswerQuery(question.id + "-" + user?.uid);
  const [updateAnswer] = useSetAnswerMutation();

  useEffect(() => {
    console.log("answer", answer);
  }, [answer]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = (event.target as HTMLInputElement).value;
    await updateAnswer({
      id: question.id + "-" + user?.uid,
      values: {
        option,
        companyId: member?.companyId,
        memberId: user?.uid,
        questionId: question.id,
      },
    });
  };

  return (
    <Accordion component={Paper}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{question.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{question.description}</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={answer?.option ?? ""}
              name="radio-buttons-group"
              onChange={handleChange}
            >
              {question.options.map((option, index) => (
                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default QuestionRow;
