import React from "react";
import { Grid, Typography } from "@mui/material";

function AnnouncementsView() {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Typography variant="h4" fontWeight={500}>
          Gestión de anuncios
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AnnouncementsView;
