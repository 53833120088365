import React, { useState } from "react";
import { Container } from "common/components";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { User } from "firebase/auth";

const headerNames = ["SUB", "Email"];

const AdminsView = () => {
  const { t } = useTranslation("common");
  const [admins, _setAdmins] = useState([] as User[]);
  console.log("TODO");

  return (
    <Container>
      <h1> {t("admin.admins.header")}</h1>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerNames.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {admins !== undefined &&
              admins !== null &&
              admins.map(
                (admin) =>
                  admin && (
                    <TableRow hover key={admin.email}>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{admin.displayName}</TableCell>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdminsView;
