import React from "react";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function WeekSelector({ nextWeek, prevWeek }: { nextWeek: () => void; prevWeek: () => void }) {
  return (
    <ButtonGroup>
      <Tooltip title="Semana anterior">
        <Button variant="contained" onClick={prevWeek}>
          <NavigateBeforeIcon />
        </Button>
      </Tooltip>
      <Tooltip title="Semana siguiente">
        <Button variant="contained" onClick={nextWeek}>
          <NavigateNextIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}

export default WeekSelector;
