import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Practitioner } from "newAPI";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { auth } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSetPractitionerMutation } from "common/redux/features/FirestoreDB";
import { AvailabilitySettings, EditAvatar } from "./components";

const tags = [
  "Aceptación y compromiso",
  "Breve",
  "Coaching",
  "Cognitivo-conductual",
  "Conductual",
  "Constructivista",
  "Dinámica",
  "EMDR",
  "Gestalt",
  "Humanista",
  "Integradora",
  "Mindfulness",
  "Positiva",
  "Psico-oncología",
  "Psicodinámica",
  "Psicopedagogía",
  "Sexual",
  "Sistémica",
];

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Too short. Please enter a valid name")
    .max(50, "Too long. Please enter a valid name"),
  title: yup
    .string()
    .trim()
    .min(2, "Too short. Please enter a valid title")
    .max(50, "Too long. Please enter a valid title"),
  summary: yup
    .string()
    .trim()
    .min(2, "Too short. Please enter a valid summary")
    .max(280, "Too long. Please enter a valid summary"),
  specialities: yup.array().of(yup.string()),
  description: yup
    .string()
    .trim()
    .min(2, "Too short. Please enter a valid description")
    .max(3500, "Too long. Please enter a valid description"),
});

function EditProfile({
  practitioner,
  openMemberView,
}: {
  practitioner: Practitioner;
  openMemberView: () => void;
}) {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const [user, _loading, _error] = useAuthState(auth);
  const [setPractitioner] = useSetPractitionerMutation();

  const initialValues = {
    name: practitioner?.name ?? "",
    title: practitioner?.title ?? "",
    summary: practitioner?.summary ?? "",
    specialities: practitioner?.specialities ?? [],
    description: practitioner?.description ?? "",
  };

  const onSubmit = async (values: {
    name: string;
    title: string;
    summary: string;
    specialities: string[];
    description: string;
  }) => {
    if (!user) return;
    if (practitioner) {
      toast.promise(setPractitioner({ id: user.uid, values }), {
        loading: t("common.toast.saving"),
        success: () => {
          openMemberView();
          return t("common.toast.savedSuccessfully");
        },
        error: t("common.toast.error"),
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Box zIndex={1000}>
        <EditAvatar practitioner={practitioner} />
      </Box>
      <Card
        sx={{
          p: { xs: 2, md: 4 },
          marginTop: theme.spacing(-10),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 1,
          height: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            width: 1,
            height: 1,
          }}
        >
          <IconButton
            sx={{
              alignSelf: "flex-end",
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.divider,
            }}
            onClick={openMemberView}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box marginTop={2} sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={2}>
                <TextField
                  sx={{
                    input: {
                      textAlign: "center",
                      fontSize: "2.125rem",
                      fontWeight: 700,
                      padding: 0,
                      height: "auto",
                    },
                    width: "100%",
                  }}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <TextField
                  sx={{
                    input: {
                      textAlign: "center",
                      fontSize: "1.25rem",
                      fontWeight: 500,
                      padding: 0,
                      color: "rgba(0, 0, 0, 0.6)",
                      height: "auto",
                    },
                    width: "100%",
                  }}
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Autocomplete
                multiple
                popupIcon={""}
                id={"specialities"}
                options={tags}
                value={formik.values.specialities}
                onChange={(e, value) => {
                  formik.setFieldValue(
                    "specialities",
                    value !== null ? value : initialValues.specialities
                  );
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={option}
                      {...getTagProps({
                        index,
                      })}
                      key={option}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"specialities"}
                    // variant="standard"
                    error={formik.touched.specialities && Boolean(formik.errors.specialities)}
                    helperText={formik.touched.specialities && formik.errors.specialities}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={700}>
                {t("practitioner.profile.summary")}
              </Typography>
              <TextField
                fullWidth
                multiline
                name="summary"
                // variant="standard"
                value={formik.values.summary}
                onChange={formik.handleChange}
                error={formik.touched.summary && Boolean(formik.errors.summary)}
                helperText={formik.touched.summary && formik.errors.summary}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={700}>
                {t("practitioner.profile.description")}
              </Typography>
              <TextField
                fullWidth
                multiline
                minRows={5}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection={{
                  xs: "column",
                  sm: "row-reverse",
                }}
                alignItems={{
                  xs: "stretched",
                  sm: "center",
                }}
                justifyContent={"space-between"}
                width={1}
              >
                <Button variant={"contained"} onClick={() => formik.handleSubmit()}>
                  {t("common.buttons.save")}
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={700}>
                {t("practitioner.profile.availabilitySettings")}
              </Typography>
              <AvailabilitySettings practitioner={practitioner} />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
}

export default EditProfile;
