import React from "react";
import WorkIcon from "@mui/icons-material/Work";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Menu } from "types/navigation";

export const menu: Menu = {
  slug: "/user",
  pages: [
    // members
    {
      title: "common.navigation.header.home",
      to: "/user",
      group: "Members",
    },
    {
      title: "common.navigation.header.announcements",
      to: "/user/announcements",
      group: "Members",
    },
    {
      title: "common.navigation.header.questions",
      to: "/user/questions",
      group: "Members",
    },
    {
      title: "common.navigation.header.practitioners",
      to: "/user/practitioners",
      group: "Members",
    },
    {
      title: "common.navigation.header.appointments",
      to: "/user/appointments",
      group: "Members",
    },
    // managers
    {
      title: "common.navigation.header.metrics",
      to: "/user/metrics",
      group: "Managers",
    },
    {
      title: "common.navigation.header.org",
      to: "/user/org",
      group: "Managers",
    },
    // practitioners
    {
      title: "common.navigation.header.home",
      to: "/practitioner",
      group: "Practitioners",
    },
    {
      title: "common.navigation.header.profile",
      to: "/practitioner/profile",
      group: "Practitioners",
    },
    {
      title: "common.navigation.header.appointments",
      to: "/practitioner/appointments",
      group: "Practitioners",
    },
    // admin
    {
      title: "common.navigation.header.home",
      to: "/admin",
      group: "Admins",
    },
    {
      title: "common.navigation.header.plans",
      to: "/admin/plans",
      group: "Admins",
    },
    {
      title: "common.navigation.header.companies",
      to: "/admin/companies",
      group: "Admins",
    },
    {
      title: "common.navigation.header.practitioners",
      to: "/admin/practitioners",
      group: "Admins",
    },
    {
      title: "common.navigation.header.managers",
      to: "/admin/managers",
      group: "Admins",
    },
    {
      title: "common.navigation.header.members",
      to: "/admin/members",
      group: "Admins",
    },
    {
      title: "common.navigation.header.admins",
      to: "/admin/admins",
      group: "Admins",
    },
    // public
    {
      title: "common.navigation.header.aboutUs",
      to: "/about",
    },
    {
      title: "common.navigation.header.ourPractitioners",
      to: "/our-practitioners",
    },
  ],
  terms: {
    privacy: {
      title: "common.navigation.footer.privacy",
      to: "/privacy",
    },
    legal: {
      title: "common.navigation.footer.legal",
      to: "/legal",
    },
  },
  footerColumn2: [
    {
      title: "common.navigation.footer2.workWithUs",
      to: "/careers",
    },
    {
      title: "common.navigation.footer2.faq",
      to: "/faq",
    },
  ],
  account: [
    {
      title: "common.account.title",
      to: "/user/account",
      group: "Members",
    },
    {
      title: "common.account.title",
      to: "/practitioner/account",
      group: "Practitioners",
    },
  ],
  accountTabs: [
    {
      id: "common.account.general.title",
      to: "/user/account",
      group: "Members",
    },
    {
      id: "common.account.security.title",
      to: "/user/account/security",
      group: "Members",
    },
    {
      id: "common.account.plan.title",
      to: "/user/account/plan",
      group: "Members",
    },
    {
      id: "common.account.general.title",
      to: "/practitioner/account",
      group: "Practitioners",
    },
    {
      id: "common.account.security.title",
      to: "/practitioner/account/security",
      group: "Practitioners",
    },
  ],
  contact: [
    {
      title: "common.contactForm.companyVideoCall.title",
      body: "common.contactForm.companyVideoCall.body",
      avatar: <ContactPhoneIcon />,
      to: "",
    },
    {
      title: "common.contactForm.companyForm.title",
      body: "common.contactForm.companyForm.body",
      avatar: <ContactMailIcon />,
      footer: "common.contactForm.companyForm.footer",
      to: "/contact",
    },
    {
      title: "common.contactForm.employee.title",
      body: "common.contactForm.employee.body",
      avatar: <WorkIcon />,
      footer: "common.contactForm.employee.footer",
      to: "",
    },
    {
      title: "common.contactForm.practitioner.title",
      body: "common.contactForm.practitioner.body",
      avatar: <AddReactionIcon />,
      footer: "",
      to: "/careers/psychologist",
    },
  ],
  myOrg: [
    {
      id: "common.myOrg.myPlan.title",
      to: "/user/org",
      group: "Managers",
      divider: true,
    },
    {
      id: "common.myOrg.announcements.title",
      to: "/user/org/announcements",
      group: "Managers",
      divider: true,
    },
    {
      id: "common.myOrg.questions.title",
      to: "/user/org/questions",
      group: "Managers",
      divider: true,
    },
    {
      id: "common.myOrg.appointments.title",
      to: "/user/org/appointments",
      group: "Managers",
      divider: true,
    },
    {
      id: "common.myOrg.members.title",
      to: "/user/org/members",
      group: "Managers",
      divider: false,
    },
    {
      id: "common.myOrg.deletedMembers.title",
      to: "/user/org/deletedMembers",
      group: "Managers",
      divider: false,
    },
  ],
};
