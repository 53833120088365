import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Question } from "newAPI";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useSetQuestionMutation } from "common/redux/features/FirestoreDB";

function QuestionRow({ question }: { question: Question }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation("common");
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [updateQuestion] = useSetQuestionMutation();

  async function archiveQuestionAction() {
    toast.promise(updateQuestion({ id: question.id, values: { status: "ARCHIVADA" } }), {
      loading: t("common.toast.deletingQuestion"),
      success: () => {
        handleClose();
        return t("common.toast.archivedQuestionSuccessfully");
      },
      error: t("common.toast.error"),
    });
  }

  async function publishQuestionAction() {
    toast.promise(updateQuestion({ id: question.id, values: { status: "PUBLICADA" } }), {
      loading: t("common.toast.publishingQuestion"),
      success: t("common.toast.publishedQuestionSuccessfully"),
      error: t("common.toast.error"),
    });
  }

  async function draftQuestionAction() {
    toast.promise(updateQuestion({ id: question.id, values: { status: "BORRADOR" } }), {
      loading: t("common.toast.draftingQuestion"),
      success: t("common.toast.draftedQuestionSuccessfully"),
      error: t("common.toast.error"),
    });
  }

  return (
    <TableRow
      hover
      key={question.id}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row" sx={{ pt: 1, pb: 1 }}>
        <List sx={{ p: 0, m: 0 }}>
          <Tooltip title={t("question.orgView.seeQuestionTooltip")}>
            <ListItemButton
              component={RouterLink}
              to={"/user/org/questions/" + question.id}
              sx={{ p: 0, m: 0 }}
            >
              <ListItem sx={{ p: 0, m: 0 }}>
                <ListItemText primary={question?.title} secondary={question?.description} />
              </ListItem>
            </ListItemButton>
          </Tooltip>
        </List>
      </TableCell>
      <TableCell>
        <Typography
          variant={"caption"}
          fontWeight={700}
          sx={{
            bgcolor: alpha(
              question.status === "PUBLICADA"
                ? theme.palette.success.light
                : question.status === "ARCHIVADA"
                ? theme.palette.error.light
                : theme.palette.warning.light,
              0.1
            ),
            color:
              question.status === "PUBLICADA"
                ? theme.palette.success.dark
                : question.status === "ARCHIVADA"
                ? theme.palette.error.dark
                : theme.palette.warning.dark,
            paddingX: 1.5,
            paddingY: 0.5,
            borderRadius: 4,
            display: "inline",
          }}
        >
          {question.status}
        </Typography>
      </TableCell>
      <TableCell>
        {question.status == "PUBLICADA" && (
          <IconButton onClick={draftQuestionAction} aria-label="archive">
            <CancelScheduleSendIcon />
          </IconButton>
        )}
        {question.status == "BORRADOR" && (
          <IconButton onClick={publishQuestionAction} aria-label="archive">
            <SendIcon />
          </IconButton>
        )}
        {question.status != "ARCHIVADA" && (
          <IconButton onClick={handleOpen} aria-label="archive">
            <ArchiveIcon />
          </IconButton>
        )}
      </TableCell>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t("question.orgView.archiveQuestionDialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("question.orgView.archiveQuestionDialog.body", {
              name: question.title,
              email: question.description,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
          <Button onClick={archiveQuestionAction} autoFocus>
            {t("common.buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}

export default QuestionRow;
