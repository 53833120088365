import React from "react";
// layout
import { Container } from "common/components";
import { Avatar, Box, Card, Chip, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useGetPractitionerQuery } from "common/redux/features/FirestoreDB";
import PractitionerAvailability from "./PractitionerAvailability";

function PractitionerView() {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();

  const { data: practitioner } = useGetPractitionerQuery(id ?? "");

  if (id) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Container maxWidth={1200} mt={{ xs: 2, sm: 0 }}>
          <Box
            display={"flex"}
            mt={2}
            flexDirection={"column"}
            alignItems={"center"}
            zIndex={1000}
            sx={{ width: "100%" }}
          >
            <Avatar
              src={practitioner?.avatar ?? ""}
              variant={"circular"}
              sx={{
                zIndex: 1000,
                width: {
                  xs: theme.spacing(16),
                  sm: theme.spacing(20),
                },
                height: {
                  xs: theme.spacing(16),
                  sm: theme.spacing(20),
                },
                border: `8px solid ${theme.palette.background.paper}`,
              }}
            />
            <Card
              sx={{
                p: { xs: 2, md: 4 },
                marginTop: theme.spacing(-10),
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 1,
                height: 1,
              }}
            >
              <IconButton
                component={RouterLink}
                to={"/user/practitioners"}
                sx={{
                  alignSelf: "flex-start",
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.divider,
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Box marginTop={2} sx={{ width: "100%" }}>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={2}>
                  <Typography fontWeight={700} variant={"h4"}>
                    {practitioner?.name}
                  </Typography>
                </Box>
                <Typography color={"text.secondary"} variant={"h6"} align={"center"}>
                  {practitioner?.title}
                </Typography>
                <Stack spacing={2} marginTop={4} width={1} alignItems={"center"}>
                  <Stack
                    direction={{
                      xs: "column",
                      md: "row",
                    }}
                    spacing={{
                      xs: 1,
                      md: 2,
                    }}
                  >
                    {practitioner?.specialities?.map((speciality) => (
                      <Chip
                        key={speciality}
                        label={speciality}
                        color="primary"
                        variant="outlined"
                      />
                    ))}
                  </Stack>

                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5" fontWeight={700}>
                      {t("member.practitionersView.summary")}
                    </Typography>
                    <Typography variant="body1" align="justify">
                      {practitioner?.summary}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5" fontWeight={700}>
                      {t("member.practitionersView.description")}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="justify"
                      style={{
                        whiteSpace: "pre-line",
                      }}
                    >
                      {practitioner?.description}
                    </Typography>
                  </Box>
                  {practitioner && <PractitionerAvailability practitionerId={practitioner.id} />}
                </Stack>
              </Box>
            </Card>
          </Box>
        </Container>
      </Box>
    );
  } else {
    return <></>;
  }
}

export default PractitionerView;
