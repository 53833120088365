import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment-timezone";
import { Practitioner } from "newAPI";
import { useTranslation } from "react-i18next";

import "moment/locale/es"; // without this line it didn't work
import { auth } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSetPractitionerMutation } from "common/redux/features/FirestoreDB";
moment.locale("es");

const tzs = moment.tz.zonesForCountry("ES");
const weekArray = moment.weekdays();
const hoursDay: string[] = [];
new Array(24).fill(undefined).forEach((acc, index) => {
  hoursDay.push(moment({ hour: index }).format("HH:mm"));
  hoursDay.push(moment({ hour: index, minute: 30 }).format("HH:mm"));
});

const validationSchema = yup.object({
  timezone: yup.string().oneOf(tzs),
  workingDays: yup.array().of(yup.number()),
  startTime: yup.string().oneOf(hoursDay),
  endTime: yup.string().oneOf(hoursDay),
});

function AvailabilitySettings({ practitioner }: { practitioner: Practitioner }) {
  const { t } = useTranslation("common");
  const [user, _loading, _error] = useAuthState(auth);
  const [setPractitioner] = useSetPractitionerMutation();

  const initialValues = {
    timezone: practitioner?.timezone ?? "",
    workingDays: practitioner?.workingDays ?? [],
    startTime: practitioner?.startTime ?? "",
    endTime: practitioner?.endTime ?? "",
  };

  const onSubmit = async (values: {
    timezone: string;
    workingDays: number[];
    startTime: string;
    endTime: string;
  }) => {
    if (!user) return;
    if (practitioner) {
      toast.promise(setPractitioner({ id: user.uid, values }), {
        loading: t("common.toast.saving"),
        success: () => {
          return t("common.toast.savedSuccessfully");
        },
        error: t("common.toast.error"),
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
          {t("practitioner.profile.timezone")}
        </Typography>
        <FormControl fullWidth>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <InputLabel id="companyId-label">{`${t("practitioner.profile.timezone")} *`}</InputLabel>
          <Select
            labelId="timezone-label"
            id="timezone"
            name="timezone"
            value={formik.values.timezone}
            onChange={formik.handleChange}
            error={formik.touched.timezone && Boolean(formik.errors.timezone)}
          >
            {tzs.map((tz) => (
              <MenuItem key={tz} value={tz}>
                {tz}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.touched.timezone && formik.errors.timezone}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
          {t("practitioner.profile.workingDays")}
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="workingDays-label">{t("practitioner.profile.workingDays")}</InputLabel>
          <Select
            labelId="workingDays-label"
            id="workingDays"
            multiple
            name="workingDays"
            value={formik.values.workingDays}
            onChange={formik.handleChange}
            input={<OutlinedInput label="Name" />}
            error={formik.touched.workingDays && Boolean(formik.errors.workingDays)}
          >
            {weekArray.map((day, i) => (
              <MenuItem key={i} value={i}>
                {day}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.touched.workingDays && formik.errors.workingDays}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
          {t("practitioner.profile.startTime")}
        </Typography>
        <FormControl fullWidth>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <InputLabel id="companyId-label">{`${t("practitioner.profile.startTime")} *`}</InputLabel>
          <Select
            labelId="startTime-label"
            id="startTime"
            name="startTime"
            value={formik.values.startTime}
            onChange={formik.handleChange}
            error={formik.touched.startTime && Boolean(formik.errors.startTime)}
          >
            {hoursDay.map((hour) => (
              <MenuItem key={hour} value={hour}>
                {hour}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.touched.startTime && formik.errors.startTime}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
          {t("practitioner.profile.endTime")}
        </Typography>
        <FormControl fullWidth>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <InputLabel id="companyId-label">{`${t("practitioner.profile.endTime")} *`}</InputLabel>
          <Select
            labelId="endTime-label"
            id="endTime"
            name="endTime"
            value={formik.values.endTime}
            onChange={formik.handleChange}
            error={formik.touched.endTime && Boolean(formik.errors.endTime)}
          >
            {hoursDay.map((hour) => (
              <MenuItem key={hour} value={hour}>
                {hour}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.touched.endTime && formik.errors.endTime}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item container xs={12}>
        <Box
          display="flex"
          flexDirection={{
            xs: "column",
            sm: "row-reverse",
          }}
          alignItems={{
            xs: "stretched",
            sm: "center",
          }}
          justifyContent={"space-between"}
          width={1}
        >
          <Button variant={"contained"} onClick={() => formik.handleSubmit()}>
            {t("common.buttons.save")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AvailabilitySettings;
