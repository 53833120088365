import React from "react";
import { Container } from "common/components";
import { useTranslation } from "react-i18next";
import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import { useGetPractitionerQuery } from "common/redux/features/FirestoreDB";

function HomeView() {
  const [user, _loading, _error] = useAuthState(auth);
  const { t } = useTranslation("common");

  const { data: practitioner } = useGetPractitionerQuery(user?.uid ?? "");

  return (
    <Container mt={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={200}>
            {t("practitioner.homeView.firstLine", {
              name: practitioner?.name,
            })}
          </Typography>
          <Typography mt={3} fontWeight={200}>
            {t("practitioner.homeView.question")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                {t("practitioner.homeView.seeProfile")}
              </Typography>
              <Typography variant="body2">{t("practitioner.homeView.whatIsSeeProfile")}</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={RouterLink} to={"/practitioner/profile"}>
                {t("practitioner.homeView.goSeeProfile")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                {t("practitioner.homeView.seeAppointments")}
              </Typography>
              <Typography variant="body2">
                {t("practitioner.homeView.whatIsSeeAppointments")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={RouterLink} to={"/practitioner/appointments"}>
                {t("practitioner.homeView.goSeeAppointments")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomeView;
