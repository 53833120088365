// firebase.config.prod.ts
export const firebaseConfig = {
  // Production environment Firebase configuration
  apiKey: "AIzaSyD9H180grrURgfT7TdO8iRQ-7Ka9K1RuWE",
  authDomain: "app-aita-care-prod.firebaseapp.com",
  projectId: "app-aita-care-prod",
  storageBucket: "app-aita-care-prod.appspot.com",
  messagingSenderId: "503589928204",
  appId: "1:503589928204:web:106b02dc17ba7f06975d29",
  measurementId: "G-22ML8NKR74",
};
