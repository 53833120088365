import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import moment from "moment";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WeekSelector from "./components";
import { useListPractitionerAvailabilityQuery } from "common/redux/features/FirestoreDB";

function PractitionerAvailability({ practitionerId }: { practitionerId: string | undefined }) {
  const { t } = useTranslation("common");
  // The following is used to store the week in display
  const [week, setWeek] = React.useState(new Map<string, moment.Moment[]>());
  // The following is used to store the entire availability of the user, split by days
  const [availabilityWeek, setAvailabilityWeek] = React.useState(
    new Map<string, moment.Moment[]>()
  );
  // The following is used to mark the week in display
  const [pointer, setPointer] = React.useState(moment(0, "HH"));

  const { data: availabilities } = useListPractitionerAvailabilityQuery({
    practitionerId: practitionerId ?? "",
  });

  /**
   * This function is triggered by:
   * - a change in availabilities (source: redux)
   *
   * And it maps them to an availabilityWeek variable
   */
  useEffect(() => {
    const workingAvailabilityWeek: Map<string, moment.Moment[]> = new Map<
      string,
      moment.Moment[]
    >();

    if (!availabilities) {
      return;
    }
    for (let j = 0; j < availabilities.length; j++) {
      if (!availabilities[j]) {
        continue;
      }
      // Push to workingAvailabilityWeek
      const day = moment(availabilities[j]).startOf("day").toISOString();
      let list = workingAvailabilityWeek.get(day);
      if (list) {
        list.push(moment(availabilities[j]));
      } else {
        list = [moment(availabilities[j])];
      }
      workingAvailabilityWeek.set(day, list);
    }

    setAvailabilityWeek(workingAvailabilityWeek);
  }, [availabilities]);

  /**
   * This function is triggered by a change in:
   * - the pointer: that defines the week in display
   * - the availabilityWeek: that defines the entire map of availability slots
   *
   * And it sets the week in display
   */
  React.useEffect(() => {
    const workingWeek: Map<string, moment.Moment[]> = new Map<string, moment.Moment[]>();

    for (let i = 1; i < 8; i++) {
      // Get the first day of the current week
      const day = moment(pointer).startOf("week").day(i).toISOString();
      const workingDay = availabilityWeek.get(day);
      // Push it to the workingWeek
      if (workingDay) {
        workingWeek.set(day, workingDay);
      } else {
        workingWeek.set(day, []);
      }
    }
    // Set the week as the workingWeek
    setWeek(workingWeek);
  }, [pointer, availabilityWeek]);

  /**
   * This function sets the week pointer to next week
   */
  function nextWeek() {
    setPointer((ps) => moment(ps).add(7, "day"));
  }

  /**
   * This function sets the week pointer to last week
   */
  function prevWeek() {
    setPointer((ps) => moment(ps).subtract(7, "day"));
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          {t("practitioner.profile.availability")}
        </Typography>
        <WeekSelector nextWeek={nextWeek} prevWeek={prevWeek} />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={1} columns={{ sm: 7 }}>
          {Array.from(week.keys()).map((weekDay) => (
            <Grid key={weekDay} item sm={1}>
              <Typography>
                <Moment format="dddd DD MMMM">{weekDay}</Moment>
              </Typography>
              <Stack spacing={1}>
                {week.get(weekDay)?.map((datetime) => (
                  <Button
                    key={datetime.toISOString()}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    <Moment format="HH:mm">{datetime}</Moment>
                  </Button>
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default PractitionerAvailability;
