import React from "react";
import { Container } from "common/components";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useListPractitionersQuery } from "common/redux/features/FirestoreDB";
import CreatePractitionerFormDialog from "./components/CreatePractitionerFormDialog";
import PractitionersRow from "./components/PractitionersRow";

const headerNames = ["SUB", "Name", "Email", "Verified", "Actions"];

const PractitionersView = () => {
  const { data: practitioners } = useListPractitionersQuery(undefined);

  return (
    <Container>
      <CreatePractitionerFormDialog />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerNames.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {practitioners &&
              practitioners.map((practitioner) => (
                <PractitionersRow key={practitioner.id} practitioner={practitioner} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PractitionersView;
