import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useManagerCreateMemberMutation } from "common/redux/features/FirestoreDB";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify the user's name"),
  email: yup
    .string()
    .trim()
    .min(2, "Please enter a valid email")
    .max(50, "Please enter a valid email")
    .required("Please specify the user's email"),
});

const CreateMemberFormDialog = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });
  const [open, setOpen] = useState(false);
  const initialValues = {
    name: "",
    email: "",
  };

  const [managerCreateMember] = useManagerCreateMemberMutation();

  const onSubmit = async (values: { name: string; email: string }) => {
    toast.promise(managerCreateMember(values), {
      loading: t("common.toast.creating"),
      success: () => {
        setOpen(false);
        return t("common.toast.memberCreatedSuccessfully");
      },
      error: t("common.toast.error"),
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      display="flex"
      flexDirection={{
        xs: "column",
        sm: "row-reverse",
      }}
      alignItems={{
        xs: "stretched",
        sm: "center",
      }}
      justifyContent={"space-between"}
      width={1}
    >
      <Box sx={{ width: !isSm ? "auto" : 1 }}>
        <Button variant="contained" onClick={handleClickOpen} fullWidth={isMd}>
          {t("admin.members.createMember")}
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>{t("admin.members.createMember")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("admin.members.dialog")}</DialogContentText>
            <TextField
              autoFocus
              name="name"
              label={`${t("admin.common.labels.name")} *`}
              type="text"
              fullWidth
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              name="email"
              label={`${t("admin.common.labels.email")} *`}
              type="text"
              fullWidth
              variant="standard"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
            <Button type="submit">{t("common.buttons.create")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default CreateMemberFormDialog;
