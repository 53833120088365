import { NotFoundView } from "common/components";
import { Route, Routes } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import React from "react";
import MembersView from "./5_MembersView/MembersView";
import LayoutPage from "./0_LayoutPage/LayoutPage";
import OrgAppointmentsView from "./4_AppointmentsView/AppointmentsView";
import DeletedMembersView from "./6_DeletedMembersView/DeletedMembersView";
import MyPlanView from "./1_MyPlanView/MyPlanView";
import MemberView from "./5_1_MemberView/MemberView";
import AnnouncementsView from "./2_AnnouncementsView/AnnouncementsView";
import QuestionsView from "./3_QuestionsView/QuestionsView";
import QuestionView from "./3_1_QuestionView/QuestionView";

function OrgView() {
  return (
    <Box bgcolor={"primary.light"}>
      <LayoutPage>
        <Routes>
          <Route index element={<MyPlanView />} />
          <Route path="announcements" element={<AnnouncementsView />} />
          <Route path="questions" element={<QuestionsView />} />
          <Route path="questions/:id" element={<QuestionView />} />
          <Route path="appointments" element={<OrgAppointmentsView />} />
          <Route path="members" element={<MembersView />} />
          <Route path="members/:id" element={<MemberView />} />
          <Route path="deletedMembers" element={<DeletedMembersView />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </LayoutPage>
      <Divider />
    </Box>
  );
}

export default OrgView;
