import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Grid } from "@mui/material";
import { sendEmailVerification } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";

function VerifyEmail() {
  const [user, _loading, _error] = useAuthState(auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!user) return;
    sendEmailVerification(user);
  }, [searchParams, user, setSearchParams, navigate, t]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <CircularProgress color={"primary"} />;
    </Grid>
  );
}

export default VerifyEmail;
