import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import moment from "moment";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Availability } from "newAPI";
import WeekSelector from "./components";
import {
  useListPractitionerAvailabilityQuery,
  useMemberBookAppointmentMutation,
} from "common/redux/features/FirestoreDB";

function PractitionerAvailability({ practitionerId }: { practitionerId: string | undefined }) {
  // TODO tweak so it works for both user's and practitioners. Now it only works for the former, and it breaks for the latter
  const { t } = useTranslation("common");
  // The following is used to store the week in display
  const [week, setWeek] = React.useState(new Map<string, moment.Moment[]>());
  // The following is used to store the entire availability of the user, split by days
  const [availabilityWeek, setAvailabilityWeek] = React.useState(
    new Map<string, moment.Moment[]>()
  );
  // The following is used to mark the week in display
  const [pointer, setPointer] = React.useState(moment(0, "HH"));
  // The following is used to redirect in case the user successfully books an appointment
  const navigate = useNavigate();
  // This is used for the confirmation dialog
  const [slot, setSlot] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const { data: availabilities } = useListPractitionerAvailabilityQuery({
    practitionerId: practitionerId ?? "",
  });

  /**
   * This function is triggered by:
   * - a change in availabilities (source: redux)
   *
   * And it maps them to an availabilityWeek variable
   */
  useEffect(() => {
    const workingAvailabilityWeek: Map<string, moment.Moment[]> = new Map<
      string,
      moment.Moment[]
    >();

    if (!availabilities) {
      return;
    }
    for (let j = 0; j < availabilities.length; j++) {
      if (!availabilities[j]) {
        continue;
      }
      // Push to workingAvailabilityWeek
      const day = moment(availabilities[j]).startOf("day").toISOString();
      let list = workingAvailabilityWeek.get(day);
      if (list) {
        list.push(moment(availabilities[j]));
      } else {
        list = [moment(availabilities[j])];
      }
      workingAvailabilityWeek.set(day, list);
    }

    setAvailabilityWeek(workingAvailabilityWeek);
  }, [availabilities]);

  /**
   * This function is triggered by a change in:
   * - the pointer: that defines the week in display
   * - the availabilityWeek: that defines the entire map of availability slots
   *
   * And it sets the week in display
   */
  React.useEffect(() => {
    const workingWeek: Map<string, moment.Moment[]> = new Map<string, moment.Moment[]>();

    for (let i = 1; i < 8; i++) {
      // Get the first day of the current week
      const day = moment(pointer).startOf("week").day(i).toISOString();
      const workingDay = availabilityWeek.get(day);
      // Push it to the workingWeek
      if (workingDay) {
        workingWeek.set(day, workingDay);
      } else {
        workingWeek.set(day, []);
      }
    }
    // Set the week as the workingWeek
    setWeek(workingWeek);
  }, [pointer, availabilityWeek]);

  /**
   * This function sets the week pointer to next week
   */
  function nextWeek() {
    setPointer((ps) => moment(ps).add(7, "day"));
  }

  /**
   * This function sets the week pointer to last week
   */
  function prevWeek() {
    setPointer((ps) => moment(ps).subtract(7, "day"));
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [memberBookAppointment] = useMemberBookAppointmentMutation();

  /**
   * This function is triggerend whenever the user clicks on an availability slot.
   * It sends a GraphQL mutation to book an appointment.
   *
   * @param e
   */
  async function onCreateAppointment() {
    if (practitionerId) {
      toast.promise(
        memberBookAppointment({
          practitionerId: practitionerId,
          slotDateTime: slot,
        }),
        {
          loading: t("common.toast.booking"),
          success: () => {
            handleClose();
            navigate("/user/appointments");
            return t("common.toast.bookedSuccessfully");
          },
          error: (err) => {
            return err;
          },
        }
      );
    }

    handleClose();
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          {t("practitioner.profile.availability")}
        </Typography>
        <WeekSelector nextWeek={nextWeek} prevWeek={prevWeek} />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={1} columns={{ sm: 7 }}>
          {Array.from(week.keys()).map((weekDay) => (
            <Grid key={weekDay} item sm={1}>
              <Typography>
                <Moment format="dddd DD MMMM">{weekDay}</Moment>
              </Typography>
              <Stack spacing={1}>
                {week.get(weekDay)?.map((datetime) => (
                  <Button
                    key={datetime.toISOString()}
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSlot(datetime.toISOString());
                      handleOpen();
                    }}
                  >
                    <Moment format="HH:mm">{datetime}</Moment>
                  </Button>
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t("member.practitionersView.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("member.practitionersView.dialog.body", {
              date: moment(slot).format("dddd D"),
              month: moment(slot).format("MMMM"),
              time: moment(slot).format("HH:mm"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
          <Button onClick={onCreateAppointment} autoFocus>
            {t("common.buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PractitionerAvailability;
