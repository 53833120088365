import React from "react";
import { Container } from "common/components";
import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HomeElementType } from "types/admin/home";
import { Link as RouterLink } from "react-router-dom";

function HomeView() {
  const { t } = useTranslation("common");
  return (
    <Container marginTop={{ xs: 4, md: 4 }}>
      <Grid container spacing={2}>
        {(
          t("admin.home", {
            returnObjects: true,
          }) as Array<HomeElementType>
        ).map((item, i) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h5" component="div">
                  {item.title}
                </Typography>
                <Typography variant="body2">{item.description}</Typography>
              </CardContent>
              <CardActions>
                <Button size="small" component={RouterLink} to={item.to}>
                  {item.button}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default HomeView;
