import React, { useEffect, useState } from "react";
import { Container } from "common/components";
// import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useGetMemberQuery, useListQuestionsQuery } from "common/redux/features/FirestoreDB";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import QuestionRow from "./components/QuestionRow";

function QuestionsView() {
  const [user, _loading, _error] = useAuthState(auth);

  const { data: member } = useGetMemberQuery(user?.uid ?? "");

  const [skip, setSkip] = useState(true);
  useEffect(() => {
    if (member) setSkip(false);
  }, [member]);

  const { data: questions } = useListQuestionsQuery(
    [
      { field: "companyId", op: "==", value: member?.companyId },
      { field: "status", op: "==", value: "PUBLICADA" },
    ],
    { skip }
  );
  // const { t } = useTranslation("common");

  return (
    <Box bgcolor={"primary.light"}>
      <Container mt={{ xs: 4, sm: 2 }}>
        {questions?.length == 0 && <Typography my={2}>No hay preguntas.</Typography>}
        {questions?.length !== 0 && (
          <Box>
            {questions?.map(
              (question, index) =>
                question !== null && <QuestionRow key={index} question={question} />
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default QuestionsView;
