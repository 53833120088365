import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { auth } from "firebase.config";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Logo from "common/components/Main/components/common/Logo";
import { toast } from "react-hot-toast";
import { sendPasswordResetEmail } from "firebase/auth";

function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, _error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);

  const [loginLoading, setLoginLoading] = React.useState(false);

  async function ActionPasswordReset() {
    if (!loginLoading) {
      setLoginLoading(true);
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          setLoginLoading(false);
          toast.success("Se ha enviado un enlace a tu correo electrónico.");
          navigate("/");
        })
        .catch(() => {
          setLoginLoading(false);
          toast.error("Error");
        });
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Logo xs={300} md={500} />
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <TextField
            autoFocus
            type="email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Correo electrónico"
            margin="normal"
          />
        </CardContent>
        <CardActions sx={{ mt: -2, mx: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                disabled={loginLoading}
                onClick={ActionPasswordReset}
              >
                {loginLoading && (
                  <CircularProgress
                    color="inherit"
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
                Send password reset email
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth component={RouterLink} to={"/"}>
                Volver al log in
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <Typography mt={4} variant="body2">
        © 2022-2023 aita.care. Todos los derechos reservados
      </Typography>
    </Grid>
  );
}
export default Reset;
