import React from "react";
import { Container } from "common/components";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import Settings from "@mui/icons-material/Psychology";

import {
  useAdminTurnManagerIntoMemberMutation,
  useAdminTurnMemberIntoManagerMutation,
  useGetMemberQuery,
  useListCompaniesQuery,
  useSetMemberMutation,
} from "common/redux/features/FirestoreDB";
import EditAvatar from "./EditAvatar";

const validationSchema = yup.object({
  credits: yup
    .number()
    .integer()
    .moreThan(-1, "Please specify a valid credits configuration (>=0)")
    .required("Please specify the credits configuration"),
  companyId: yup
    .string()
    .trim()
    .min(2, "Please enter a valid company")
    .max(50, "Please enter a valid company")
    .required("Please specify the user's company"),
});

function MemberView() {
  const { t } = useTranslation("common");
  const { id } = useParams();

  const { data: member } = useGetMemberQuery(id ?? "");
  const { data: companies } = useListCompaniesQuery(undefined);

  const [adminTurnManagerIntoMember] = useAdminTurnManagerIntoMemberMutation();
  const [adminTurnMemberIntoManager] = useAdminTurnMemberIntoManagerMutation();

  const [disabled, setDisabled] = React.useState(true);
  const initialValues = {
    name: member?.name ?? "",
    credits: member?.credits ?? 0,
    companyId: member?.companyId ?? "",
  };

  const onEdit = () => {
    setDisabled((ps) => !ps);
  };

  const [setMember] = useSetMemberMutation();

  const onSubmit = async (values: { name: string; credits: number; companyId: string }) => {
    const newCompanyId = companies?.find(
      (company) => company !== null && company.id === values.companyId
    )?.id;
    if (!newCompanyId) return;
    console.log(member);
    if (member && member.id) {
      toast.promise(setMember({ id: member.id, values }), {
        loading: t("common.toast.saving"),
        success: () => {
          onEdit();
          return t("common.toast.memberUpdatedSuccessfully");
        },
        error: t("common.toast.error"),
      });
    } else {
      toast("User doesn't exist in DB. In needs to be created manually!", {
        icon: <Settings />,
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>
              {t("admin.members.member")}
              {id}
            </h1>
            <Box
              sx={{
                flexDirection: "row-reverse",
              }}
            >
              {disabled && (
                <ButtonGroup variant="contained">
                  <Button variant="contained" onClick={onEdit}>
                    {t("common.buttons.edit")}
                  </Button>
                  {member?.isManager && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        toast.promise(adminTurnManagerIntoMember({ id: member?.id }), {
                          loading: t("common.toast.saving"),
                          success: t("common.toast.memberUpdatedSuccessfully"),
                          error: t("common.toast.error"),
                        });
                      }}
                    >
                      {t("common.buttons.turnMember")}
                    </Button>
                  )}
                  {!member?.isManager && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        toast.promise(adminTurnMemberIntoManager({ id: member?.id ?? "" }), {
                          loading: t("common.toast.saving"),
                          success: t("common.toast.memberUpdatedSuccessfully"),
                          error: t("common.toast.error"),
                        });
                      }}
                    >
                      {t("common.buttons.turnManager")}
                    </Button>
                  )}
                </ButtonGroup>
              )}
              {!disabled && (
                <ButtonGroup variant="contained">
                  <Button variant="contained" onClick={() => formik.handleSubmit()}>
                    {t("common.buttons.accept")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.resetForm();
                      onEdit();
                    }}
                  >
                    {t("common.buttons.cancel")}
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          {member && <EditAvatar member={member} />}
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            disabled={disabled}
            label={t("admin.common.labels.name")}
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            disabled
            label={t("admin.common.labels.email")}
            fullWidth
            name="email"
            type="email"
            value={member?.email ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            disabled={disabled}
            label={t("admin.common.labels.credits")}
            fullWidth
            name="credits"
            type="number"
            value={formik.values.credits}
            onChange={formik.handleChange}
            error={formik.touched.credits && Boolean(formik.errors.credits)}
            helperText={formik.touched.credits && formik.errors.credits}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl fullWidth disabled={disabled}>
            <InputLabel id="companyId-label">{t("admin.common.labels.company")}</InputLabel>
            <Select
              labelId="companyId-label"
              id="companyId"
              name="companyId"
              value={formik.values.companyId}
              onChange={formik.handleChange}
              error={formik.touched.companyId && Boolean(formik.errors.companyId)}
            >
              {companies?.map((company) => (
                <MenuItem key={company?.id} value={company?.id}>
                  {/* eslint-disable-next-line react/jsx-no-literals */}
                  {company?.name + " - " + company?.id}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.companyId && formik.errors.companyId}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            disabled
            label={t("admin.common.labels.isManager")}
            fullWidth
            name="isManager"
            type="isManager"
            value={member?.isManager ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            disabled
            label={t("admin.common.labels.disabled")}
            fullWidth
            name="disabled"
            type="disabled"
            value={member?.disabled ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            disabled
            label={t("admin.common.labels.deleted")}
            fullWidth
            name="deleted"
            type="deleted"
            value={member?.deleted ?? ""}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default MemberView;
