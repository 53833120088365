import React from "react";
import { Avatar, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Member } from "newAPI";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import toast from "react-hot-toast";
import { auth, storage } from "firebase.config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSetMemberMutation } from "common/redux/features/FirestoreDB";

function EditAvatar({ member }: { member: Member }) {
  const [user, _loading, _error] = useAuthState(auth);
  const theme = useTheme();
  const [setMember] = useSetMemberMutation();

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const toastId = toast.loading("Actualizando...");
    try {
      if (!user) return;
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        // use member.id for the file name
        const name = member.id + file.type.split("/")[1];

        const imageRef = ref(storage, `avatars/${name}`);
        uploadBytes(imageRef, file).then(async (snapshot) => {
          const avatar = await getDownloadURL(snapshot.ref);
          setMember({ id: user.uid, values: { avatar } });
        });
      }
      toast.success("Éxito!", { id: toastId });
    } catch (err) {
      toast.error("Error", { id: toastId });
    }
  };

  return (
    <>
      <input
        accept="image/*"
        type="file"
        hidden
        onChange={onFileChange}
        id="contained-button-file"
      />
      <label htmlFor="contained-button-file">
        <IconButton component="span" sx={{ padding: 0 }}>
          <Avatar
            src={member.avatar ?? ""}
            variant={"circular"}
            sx={{
              width: {
                xs: theme.spacing(16),
                sm: theme.spacing(20),
              },
              height: {
                xs: theme.spacing(16),
                sm: theme.spacing(20),
              },
              border: `8px solid ${theme.palette.background.paper}`,
            }}
          />
          <Avatar
            className="overlay"
            variant={"circular"}
            sx={{
              width: {
                xs: theme.spacing(16),
                sm: theme.spacing(20),
              },
              height: {
                xs: theme.spacing(16),
                sm: theme.spacing(20),
              },
              border: `8px solid ${theme.palette.background.paper}`,
              position: "absolute",
              opacity: 0,
              ":hover": { opacity: 0.5 },
            }}
          >
            <PhotoCameraIcon />
          </Avatar>
        </IconButton>
      </label>
    </>
  );
}

export default EditAvatar;
