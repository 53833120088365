import React from "react";
import { Container } from "common/components";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useListCompaniesQuery } from "common/redux/features/FirestoreDB";
import CreateCompanyFormDialog from "./components/CreateCompanyFormDialog";
import CompaniesRow from "./components/CompaniesRow";

const headerNames = ["ID", "Name", "Plan", "Board", "Questions", "Indexes", "Credits", "Actions"];

const CompaniesView = () => {
  const { data: companies } = useListCompaniesQuery(undefined);

  return (
    <Container>
      <CreateCompanyFormDialog />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerNames.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {companies !== undefined &&
              companies !== null &&
              companies.map(
                (company) => company && <CompaniesRow key={company?.id} company={company} />
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default CompaniesView;
