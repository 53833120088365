import React, { useEffect } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import { auth, logout } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LoadingSpinner() {
  const [user, _loading, _error] = useAuthState(auth);
  const { t } = useTranslation("common");

  // set groups
  const [groups, setGroups] = React.useState(["SomeGroup"]);
  useEffect(() => {
    user?.getIdTokenResult().then((idTokenResult) => {
      const isMember = idTokenResult.claims["member"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isManager = idTokenResult.claims["manager"];
      const isAdmin = idTokenResult.claims["admin"];

      const localGroups = [] as Array<string>;
      if (isMember) localGroups.push("Members");
      if (isPractitioner) localGroups.push("Practitioners");
      if (isManager) localGroups.push("Managers");
      if (isAdmin) localGroups.push("Admins");

      setGroups(localGroups);
    });
  }, [user]);

  if (groups.length == 0)
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <p>{t("auth.notice")}</p>
        <Button variant="contained" onClick={logout} component={RouterLink} to={"/"}>
          {t("common.buttons.signOut")}
        </Button>
      </Grid>
    );
  else
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <CircularProgress color="primary" />
      </Grid>
    );
}
export default LoadingSpinner;
