import React from "react";
import { Container } from "common/components";
import { useTranslation } from "react-i18next";
import { Box, Fab, Grid, Grow, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";

interface SubFab {
  id: number;
  visible: boolean;
}

function AnnouncementsView() {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const [expanded, setExpanded] = React.useState(false);
  const [subFabs, setSubFabs] = React.useState([
    { id: 1, visible: false },
    { id: 2, visible: false },
    { id: 3, visible: false },
    { id: 4, visible: false },
  ]);

  const handleFabClick = () => {
    setExpanded(!expanded);
    setSubFabs((prevFabs) =>
      prevFabs.map((fab) => ({
        ...fab,
        visible: !expanded,
      }))
    );
  };

  const handleSubFabClick = (id: number) => {
    setSubFabs((prevFabs: SubFab[]) =>
      prevFabs.map((fab, index) => ({
        ...fab,
        visible: id !== index + 1,
      }))
    );
  };

  return (
    <Container mt={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={200}>
            {t("member.announcementsView.firstLine")}
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Fab
          size="large"
          sx={{ position: "fixed", right: theme.spacing(2), bottom: theme.spacing(2) }}
          color="primary"
          aria-label="add"
          onClick={handleFabClick}
        >
          <AddIcon />
        </Fab>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            m: 1.5,
            position: "fixed",
            right: theme.spacing(1),
            bottom: theme.spacing(10),
          }}
        >
          {subFabs.map((fab) => (
            <Grow in={fab.visible} key={fab.id}>
              <Fab
                sx={{ m: 0.5 }}
                size="small"
                color="secondary"
                aria-label={`subfab-${fab.id}`}
                onClick={() => handleSubFabClick(fab.id)}
              >
                <AddIcon />
              </Fab>
            </Grow>
          ))}
        </Box>
      </div>
    </Container>
  );
}

export default AnnouncementsView;
