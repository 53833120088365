import { auth } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { Main } from "common/components";
import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useLayoutEffect } from "react";
import HomeView from "./HomeView/HomeView";
import MembersView from "./MembersView/MembersView";

import PractitionersView from "./PractitionersView/PractitionersView";
import ManagersView from "./ManagersView/ManagersView";
import AdminsViews from "./AdminsView/AdminsView";
import PlansView from "./PlansView/PlansView";
import CompaniesView from "./CompaniesView/CompaniesView";
import MemberView from "./MemberView/MemberView";
import PractitionerView from "./PractitionerView/PractitionerView";
import PlanView from "./PlanView/PlanView";
import CompanyView from "./CompanyView/CompanyView";

function AppAdmin() {
  const [user, loading, _error] = useAuthState(auth);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (loading) return;
    if (!user) navigate("/");

    user?.getIdTokenResult().then((idTokenResult) => {
      const isAdmin = idTokenResult.claims["admin"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isMember = idTokenResult.claims["member"];

      if (isAdmin) return;
      if (isPractitioner) navigate("/practitioner");
      if (isMember) navigate("/user");
    });
  }, [user, navigate]);

  // if (isAdmin) {
  return (
    <Main>
      <Routes>
        <Route index element={<HomeView />} />
        <Route path="/practitioners/*" element={<PractitionersView />} />
        <Route path="/practitioners/:id" element={<PractitionerView />} />
        <Route path="/managers/*" element={<ManagersView />} />
        <Route path="/members" element={<MembersView />} />
        <Route path="/members/:id" element={<MemberView />} />
        <Route path="/admins/*" element={<AdminsViews />} />
        <Route path="/plans" element={<PlansView />} />
        <Route path="/plans/:id" element={<PlanView />} />
        <Route path="/companies" element={<CompaniesView />} />
        <Route path="/companies/:id" element={<CompanyView />} />
      </Routes>
    </Main>
  );
  // } else {
  //   return <LoadingSpinner />;
  // }
}

// export default withAuthenticator(AppAdmin);
export default AppAdmin;
