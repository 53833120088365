import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import Settings from "@mui/icons-material/Psychology";
import { Calendar } from "newAPI";
import moment from "moment";
import {
  useGetMemberQuery,
  useGetQuestionQuery,
  useListAnswersQuery,
  useListAppointmentsQuery,
  useSetQuestionMutation,
} from "common/redux/features/FirestoreDB";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import AppConversionRates from "./components/AppConversionRates";

const validationSchema = yup.object({
  title: yup
    .string()
    .trim()
    .min(2, "Please enter a valid title")
    .max(50, "Please enter a valid title")
    .required("Please specify the user's title"),
  description: yup
    .string()
    .trim()
    .min(2, "Please enter a valid description")
    .max(50, "Please enter a valid description")
    .required("Please specify the user's description"),
  options: yup.array().of(yup.string()),
});

const STATUS_LIST = ["BORRADOR", "PUBLICADA", "ARCHIVADA"];

function QuestionView() {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  const { data: question } = useGetQuestionQuery(id ?? "");

  // const [skip, setSkip] = useState(true);
  // useEffect(() => {
  //   if (question) setSkip(false);
  // }, [question]);

  // const { data: appointments } = useListAppointmentsQuery(
  //   [
  //     { field: "companyId", op: "==", value: question?.companyId },
  //     { field: "questionId", op: "==", value: id },
  //   ],
  //   { skip }
  // );

  const [disabled, setDisabled] = React.useState(true);
  const initialValues = {
    title: question?.title ?? "",
    description: question?.description ?? "",
    status: question?.status ?? "",
    options: question?.options ?? ([] as Array<string>),
  };

  const onEdit = () => {
    setDisabled((ps) => !ps);
  };

  const [setQuestion] = useSetQuestionMutation();

  const onSubmit = async (values: {
    title: string;
    description: string;
    status: string;
    options: Array<string>;
  }) => {
    if (question && question.id) {
      toast.promise(setQuestion({ id: question.id, values }), {
        loading: t("common.toast.saving"),
        success: () => {
          onEdit();
          return t("common.toast.questionUpdatedSuccessfully");
        },
        error: t("common.toast.error"),
      });
    } else {
      toast("Question doesn't exist in DB. In needs to be created manually!", {
        icon: <Settings />,
      });
    }
  };

  const [user, _loading, _error] = useAuthState(auth);
  const { data: member } = useGetMemberQuery(user?.uid ?? "");

  const [skip, setSkip] = useState(true);
  useEffect(() => {
    if (member && question) setSkip(false);
  }, [member, question]);
  const { data: answers } = useListAnswersQuery(
    [
      { field: "companyId", op: "==", value: member?.companyId },
      { field: "questionId", op: "==", value: question?.id },
    ],
    { skip }
  );

  const [answersByOption, setAnswersByOption] = useState(
    [] as Array<{ label: string; value: number }>
  );

  useEffect(() => {
    let result =
      question?.options.map((item) => {
        return {
          label: item,
          value: 0,
        };
      }) ?? ([] as Array<{ label: string; value: number }>);

    if (!answers) return;
    for (const answer of answers) {
      result.find((value) => value.label === answer.option)?.value ?? 0;
      result.find((value) => value.label === answer.option)!.value++;
    }
    setAnswersByOption(result);
  }, [answers]);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            flexDirection={{
              xs: "column",
              sm: "row-reverse",
            }}
            alignItems={{
              xs: "stretched",
              sm: "center",
            }}
            justifyContent={"space-between"}
            width={1}
          >
            <Box sx={{ width: !isSm ? "auto" : 1 }}>
              {disabled && (
                <Button variant="contained" onClick={onEdit} fullWidth={isMd}>
                  {t("common.buttons.edit")}
                </Button>
              )}
              {!disabled && (
                <ButtonGroup variant="contained" fullWidth={isMd}>
                  <Button variant="contained" onClick={() => formik.handleSubmit()}>
                    {t("common.buttons.accept")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.resetForm();
                      onEdit();
                    }}
                  >
                    {t("common.buttons.cancel")}
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          disabled={disabled}
          label={t("admin.common.labels.title")}
          fullWidth
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          disabled={disabled}
          label={t("admin.common.labels.description")}
          fullWidth
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormControl fullWidth>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <InputLabel id="companyId-label">{`${t("admin.common.labels.plan")} *`}</InputLabel>
          <Select
            disabled={disabled}
            labelId="status-label"
            id="status"
            name="status"
            value={formik.values.status}
            onChange={formik.handleChange}
            error={formik.touched.status && Boolean(formik.errors.status)}
          >
            {STATUS_LIST?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.touched.status && formik.errors.status}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography variant="overline" fontWeight={200}>
          Opciones
        </Typography>
        <Box ml={3}>
          {formik.values.options.map((item, index) => {
            return (
              <TextField
                disabled={disabled}
                key={index}
                name={`options[${index}]`}
                fullWidth
                value={item}
                margin="dense"
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          const newOptions = [...formik.values.options];
                          newOptions.splice(index, 1);
                          formik.setFieldValue("options", newOptions);
                        }}
                        edge="end"
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            );
          })}
        </Box>
        <Button onClick={() => formik.setFieldValue("options", formik.values.options.concat([""]))}>
          Add option
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4" fontWeight={500}>
          {t("question.questionView.appointmentsHeader")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <AppConversionRates
          title={question?.title ?? ""}
          subheader={question?.description ?? ""}
          chartData={answersByOption}
          colors={[theme.palette.info.main]}
        />
      </Grid>
    </Grid>
  );
}

export default QuestionView;
