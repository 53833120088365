import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Appointment } from "newAPI";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useGetMemberQuery, useGetPractitionerQuery } from "common/redux/features/FirestoreDB";

function AppointmentViewRow({ appointment }: { appointment: Appointment }) {
  const { t } = useTranslation("common");
  const theme = useTheme();

  const { data: member } = useGetMemberQuery(appointment.memberId ?? "");
  const { data: practitioner } = useGetPractitionerQuery(appointment.practitionerId ?? "");

  return (
    <TableRow
      hover
      key={appointment.id}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row" sx={{ pt: 1, pb: 1 }}>
        <List sx={{ p: 0, m: 0 }}>
          <Tooltip title={t("member.orgView.seeMemberTooltip")}>
            <ListItemButton
              component={RouterLink}
              to={"/user/org/members/" + member?.id}
              sx={{ p: 0, m: 0 }}
            >
              <ListItem sx={{ p: 0, m: 0 }}>
                <ListItemAvatar>
                  <Avatar src={member?.avatar ?? ""} />
                </ListItemAvatar>
                <ListItemText primary={member?.name} secondary={member?.email} />
              </ListItem>
            </ListItemButton>
          </Tooltip>
        </List>
      </TableCell>
      <TableCell>
        <Typography>
          <Moment format="LLLL">{appointment.datetime}</Moment>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant={"caption"}
          fontWeight={700}
          sx={{
            bgcolor: alpha(
              appointment.status === "RESERVADA"
                ? theme.palette.success.light
                : appointment.status === "CANCELADA TARDE"
                  ? theme.palette.error.light
                  : theme.palette.warning.light,
              0.1
            ),
            color:
              appointment.status === "RESERVADA"
                ? theme.palette.success.dark
                : appointment.status === "CANCELADA TARDE"
                  ? theme.palette.error.dark
                  : theme.palette.warning.dark,
            paddingX: 1.5,
            paddingY: 0.5,
            borderRadius: 4,
            display: "inline",
          }}
        >
          {appointment.status}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row" sx={{ pt: 1, pb: 1 }}>
        <List sx={{ p: 0, m: 0 }}>
          <Tooltip title={t("member.appointmentsView.seePractitionerTooltip")}>
            <ListItemButton
              component={RouterLink}
              to={"/user/practitioners/" + practitioner?.id}
              sx={{ p: 0, m: 0 }}
            >
              <ListItem sx={{ p: 0, m: 0 }}>
                <ListItemAvatar>
                  <Avatar src={practitioner?.avatar ?? ""} />
                </ListItemAvatar>
                <ListItemText primary={practitioner?.name} secondary={practitioner?.title} />
              </ListItem>
            </ListItemButton>
          </Tooltip>
        </List>
      </TableCell>
    </TableRow>
  );
}

export default AppointmentViewRow;
