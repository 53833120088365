import React, { useEffect } from "react";
import { Container } from "common/components";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import { useGetPractitionerQuery } from "common/redux/features/FirestoreDB";
import { EditProfile, ViewProfile } from "./components";

function ProfileView() {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const [edit, setEdit] = React.useState(searchParams.get("edit") ?? false);
  const [user, _loading, _error] = useAuthState(auth);

  const { data: practitioner } = useGetPractitionerQuery(user?.uid ?? "");

  useEffect(() => {
    searchParams.set("edit", edit ? "true" : "false");
    setSearchParams(searchParams);
  }, [edit, searchParams, setSearchParams]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Container maxWidth={1200} mt={{ xs: 2, sm: 0 }}>
        <Box
          display={"flex"}
          mt={2}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          {!edit && practitioner && (
            <ViewProfile practitioner={practitioner} openEditView={() => setEdit(true)} />
          )}
          {edit && practitioner && (
            <EditProfile practitioner={practitioner} openMemberView={() => setEdit(false)} />
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default ProfileView;
