import React from "react";

import { useTheme } from "@mui/material/styles";

import { Avatar, Box, Button, Card, Chip, Grid, Stack, Typography } from "@mui/material";
import { Practitioner } from "newAPI";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function PractitionersViewCard({ practitioner }: { practitioner: Practitioner }) {
  const { t } = useTranslation("common");
  const theme = useTheme();
  return (
    <Grid key={practitioner.id} item xs={12} sm={4} marginTop={2}>
      <Card
        sx={{
          p: { xs: 2, md: 4 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 1,
          height: 1,
          background: "transparent",
          backgroundImage: `linear-gradient(0deg, ${theme.palette.background.paper} 75%, ${theme.palette.primary.main} 0%)`,
        }}
      >
        <Avatar
          src={practitioner.avatar ?? ""}
          variant={"circular"}
          sx={{
            width: 120,
            height: 120,
          }}
        />
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={2}>
          <Typography fontWeight={700}>{practitioner.name}</Typography>
        </Box>
        <Typography color={"text.secondary"}>{practitioner.title}</Typography>
        <Stack spacing={2} marginTop={1} width={1} alignItems={"center"}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {practitioner.specialities?.map((speciality) => (
              <Chip key={speciality} label={speciality} color="primary" variant="outlined" />
            ))}
          </Box>
          <Typography
            color={"text.secondary"}
            sx={{
              // whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {practitioner.summary}
          </Typography>
        </Stack>
        <Box flexGrow={1} mt={1} />
        <Button
          component={RouterLink}
          variant={"outlined"}
          color={"primary"}
          to={"/user/practitioners/" + practitioner.id}
          fullWidth
        >
          {t("member.buttons.viewProfile")}
        </Button>
      </Card>
    </Grid>
  );
}

export default PractitionersViewCard;
