import React from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link as RouterLink } from "react-router-dom";
import { Member } from "newAPI";

function MembersRow({ member }: { member: Member }) {
  return (
    <TableRow hover key={member.id}>
      <TableCell>{member.id}</TableCell>
      <TableCell>{member.name}</TableCell>
      <TableCell>{member?.credits ?? "?"}</TableCell>
      <TableCell>{member.email}</TableCell>
      <TableCell>
        <IconButton component={RouterLink} to={"/admin/members/" + member.id} aria-label="open">
          <VisibilityIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default MembersRow;
