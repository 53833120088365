import React from "react";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { auth } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useGetCompanyQuery, useGetMemberQuery } from "common/redux/features/FirestoreDB";

function Plan() {
  const [user, _loading, _error] = useAuthState(auth);
  const { t } = useTranslation("common");

  const { data: member } = useGetMemberQuery(user?.uid ?? "");
  const { data: company } = useGetCompanyQuery(member?.companyId ?? "");

  return (
    <>
      <Typography variant="h6" gutterBottom fontWeight={700}>
        {t("common.account.plan.header")}
      </Typography>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
            {t("common.account.plan.companyName")}
          </Typography>
          <TextField
            // label="Company"
            variant="outlined"
            name={"company"}
            fullWidth
            value={company?.name ?? ""}
            disabled={true}
          />
        </Grid>

        {/* user credits */}
        <Grid item xs={12}>
          <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
            {t("common.account.plan.remainingCredits")}
          </Typography>
          <TextField
            // label="credits"
            variant="outlined"
            name={"credits"}
            fullWidth
            value={member?.credits ?? 0}
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Plan;
