import React, { useEffect } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { MenuContext } from "common/context/MenuContext";
import { ContactButton } from "common/components";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import Logo from "../common/Logo";
import SideAvatar from "./SideAvatar";

function SidebarNav({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation("common");
  const [user, _loading, _error] = useAuthState(auth);
  const menu = React.useContext(MenuContext);

  // set groups
  const [groups, setGroups] = React.useState([] as Array<string>);
  useEffect(() => {
    user?.getIdTokenResult().then((idTokenResult) => {
      const isMember = idTokenResult.claims["member"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isManager = idTokenResult.claims["manager"];
      const isAdmin = idTokenResult.claims["admin"];

      const localGroups = [] as Array<string>;
      if (isMember) localGroups.push("Members");
      if (isPractitioner) localGroups.push("Practitioners");
      if (isManager) localGroups.push("Managers");
      if (isAdmin) localGroups.push("Admins");

      setGroups(localGroups);
    });
  }, [user]);

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Logo onClose={onClose} />
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          {menu.pages.map(
            (item, i) =>
              ((!groups && !item.group) || (item.group && groups?.includes(item.group))) && (
                <Box marginTop={3} key={i}>
                  <Link
                    underline="none"
                    component={RouterLink}
                    to={item.to}
                    onClick={onClose}
                    color="text.primary"
                  >
                    <Typography
                      variant="overline"
                      fontWeight={item.to === window?.location?.pathname ? 800 : 400}
                    >
                      {t(item.title)}
                    </Typography>
                  </Link>
                </Box>
              )
          )}
          {user && <SideAvatar onClose={onClose} />}
          {!user && (
            <>
              <ContactButton marginTop={3} size="large" fullWidth={true}>
                {t("common.buttons.contact")}
              </ContactButton>
              <Box marginTop={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  component={RouterLink}
                  to="/"
                  size="large"
                >
                  {t("common.buttons.access")}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default SidebarNav;
