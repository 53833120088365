import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import DeleteIcon from "@mui/icons-material/Delete";
import { Member } from "newAPI";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  useManagerDeleteMemberMutation,
  useManagerDisableMemberMutation,
  useManagerEnableMemberMutation,
} from "common/redux/features/FirestoreDB";

function ActiveMemberRow({ member }: { member: Member }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation("common");
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [managerDeleteMember] = useManagerDeleteMemberMutation();
  const [managerDisableMember] = useManagerDisableMemberMutation();
  const [managerEnableMember] = useManagerEnableMemberMutation();

  async function deleteMemberAction(id: string) {
    toast.promise(managerDeleteMember({ id }), {
      loading: t("common.toast.deletingUser"),
      success: t("common.toast.deletedUserSuccessfully"),
      error: t("common.toast.error"),
    });
  }

  async function disableMemberAction(id: string) {
    toast.promise(managerDisableMember({ id }), {
      loading: t("common.toast.disablingUser"),
      success: t("common.toast.disabledUserSuccessfully"),
      error: t("common.toast.error"),
    });
  }

  async function enableMemberAction(id: string) {
    toast.promise(managerEnableMember({ id }), {
      loading: t("common.toast.enablingUser"),
      success: t("common.toast.enabledUserSuccessfully"),
      error: t("common.toast.error"),
    });
  }

  return (
    <TableRow
      hover
      key={member.id}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row" sx={{ pt: 1, pb: 1 }}>
        <List sx={{ p: 0, m: 0 }}>
          <Tooltip title={t("member.orgView.seeMemberTooltip")}>
            <ListItemButton
              component={RouterLink}
              to={"/user/org/members/" + member.id}
              sx={{ p: 0, m: 0 }}
            >
              <ListItem sx={{ p: 0, m: 0 }}>
                <ListItemAvatar>
                  <Avatar src={member?.avatar ?? ""} />
                </ListItemAvatar>
                <ListItemText primary={member?.name} secondary={member?.email} />
              </ListItem>
            </ListItemButton>
          </Tooltip>
        </List>
      </TableCell>
      <TableCell>
        <Typography>{member.credits}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant={"caption"}
          fontWeight={700}
          sx={{
            bgcolor: alpha(
              member.disabled ? theme.palette.warning.light : theme.palette.success.light,
              0.1
            ),
            color: member.disabled ? theme.palette.warning.dark : theme.palette.success.dark,
            paddingX: 1.5,
            paddingY: 0.5,
            borderRadius: 4,
            display: "inline",
          }}
        >
          {member.disabled ? "Inactivo" : "Activo"}
        </Typography>
      </TableCell>
      <TableCell>
        {member.disabled ? (
          <IconButton onClick={() => enableMemberAction(member.id)} aria-label="enable">
            <ToggleOffIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => disableMemberAction(member.id)} aria-label="disable">
            <ToggleOnIcon />
          </IconButton>
        )}
        <IconButton onClick={handleOpen} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t("member.orgView.deleteMemberDialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("member.orgView.deleteMemberDialog.body", {
              name: member.name,
              email: member.email,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
          <Button
            onClick={() => {
              deleteMemberAction(member.id);
              handleClose();
            }}
            autoFocus
          >
            {t("common.buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}

export default ActiveMemberRow;
