import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Logo from "common/components/Main/components/common/Logo";

import { auth } from "firebase.config";
import {
  applyActionCode,
  // checkActionCode,
  confirmPasswordReset,
  // sendPasswordResetEmail,
  verifyPasswordResetCode,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";

export default function UserManagement() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mode, setMode] = useState("");
  const [actionCode, setActionCode] = useState("");
  const [continueUrl, setContinueUrl] = useState("");
  const [lang, setLang] = useState("");
  const [loginLoading, setLoginLoading] = React.useState(false);

  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  useEffect(() => {
    setMode(searchParams.get("mode") ?? "");
    setActionCode(searchParams.get("oobCode") ?? "");
    setContinueUrl(searchParams.get("continueUrl") ?? "");
    setLang(searchParams.get("lang") ?? "");
  }, [searchParams]);

  useEffect(() => {
    if (!actionCode) return;
    switch (mode) {
      case "resetPassword":
        verifyPasswordResetCode(auth, actionCode).catch((error) => {
          console.log(error);
          toast.error("El enlace de verificación no es válido...");
          navigate("/");
        });
        break;
      case "verifyEmail":
        applyActionCode(auth, actionCode)
          .then((resp) => {
            toast.error("Correo electrónico verificado con éxito.");
            navigate(continueUrl !== "" ? continueUrl : "/");
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error verificando el correo electrónico...");
            navigate("/");
          });
        break;
      default:
        // Error: invalid mode.
        navigate("/");
    }
  }, [mode, actionCode, continueUrl, lang]);

  function setPassword() {
    if (!actionCode) return;
    if (newPassword != repeatPassword) {
      toast.error("Las contraseñas no coinciden...");
      return;
    }
    confirmPasswordReset(auth, actionCode, newPassword)
      .then((resp) => {
        toast.success("Contraseña actualizada con éxito.");
        navigate("/");
      })
      .catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        toast.error(error);
        console.log(error);
      });
  }

  if (mode === "resetPassword") {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Logo xs={300} md={500} />
        <Card sx={{ mt: 4 }}>
          <CardContent>
            <TextField
              autoFocus
              type="password"
              variant="outlined"
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              label="Nueva contraseña"
              margin="normal"
            />
            <TextField
              type="password"
              variant="outlined"
              fullWidth
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              label="Repite la contraseña"
              margin="normal"
            />
          </CardContent>
          <CardActions sx={{ mt: -2, mx: 2, mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button fullWidth variant="contained" disabled={loginLoading} onClick={setPassword}>
                  {loginLoading && (
                    <CircularProgress
                      color="inherit"
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                  Establecer contraseña
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button fullWidth component={RouterLink} to={"/"}>
                  Volver al log in
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
        <Typography mt={4} variant="body2">
          © 2022-2023 aita.care. Todos los derechos reservados
        </Typography>
      </Grid>
      // <div>
      //   <div>
      //     <input
      //       type="text"
      //       value={newPassword}
      //       onChange={(e) => setNewPassword(e.target.value)}
      //       placeholder="E-mail Address"
      //     />
      //     <input
      //       type="password"
      //       value={repeatPassword}
      //       onChange={(e) => setRepeatPassword(e.target.value)}
      //       placeholder="Password"
      //     />
      //     <button onClick={setPassword}>Establecer contraseña</button>
      //     <div>
      //       <Link to="/reset">Acceder</Link>
      //     </div>
      //     <div>
      //       Don&lsquo;t have an account? <Link to="/register">Register</Link> now.
      //     </div>
      //   </div>
      // </div>
    );
  } else {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <CircularProgress color="primary" />
      </Grid>
    );
  }
}
