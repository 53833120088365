import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import { TableCell, TableRow, Typography } from "@mui/material";
import { Appointment } from "newAPI";
import Moment from "react-moment";
import MemberAvatarAndCredits from "./components/MemberAvatarAndCredits";

function AppointmentsViewCard({ appointment }: { appointment: Appointment }) {
  const theme = useTheme();

  return (
    <TableRow
      key={appointment.id}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <MemberAvatarAndCredits appointment={appointment} />
      <TableCell>
        <Typography>
          <Moment format="LLLL">{appointment.datetime}</Moment>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant={"caption"}
          fontWeight={700}
          sx={{
            bgcolor: alpha(
              appointment.status === "booked"
                ? theme.palette.success.light
                : appointment.status === "CANCELADA TARDE"
                  ? theme.palette.error.light
                  : theme.palette.warning.light,
              0.1
            ),
            color:
              appointment.status === "booked"
                ? theme.palette.success.dark
                : appointment.status === "CANCELADA TARDE"
                  ? theme.palette.error.dark
                  : theme.palette.warning.dark,
            paddingX: 1.5,
            paddingY: 0.5,
            borderRadius: 4,
            display: "inline",
          }}
        >
          {appointment.status}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default AppointmentsViewCard;
