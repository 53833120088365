import React from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link as RouterLink } from "react-router-dom";
import { Practitioner } from "newAPI";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

function PractitionersRow({ practitioner }: { practitioner: Practitioner }) {
  return (
    <TableRow hover key={practitioner.id}>
      <TableCell>{practitioner.id}</TableCell>
      <TableCell>{practitioner?.name}</TableCell>
      <TableCell>{practitioner.email}</TableCell>
      <TableCell>{practitioner?.verified ? <DoneIcon /> : <ClearIcon />}</TableCell>
      <TableCell>
        <IconButton
          component={RouterLink}
          to={"/admin/practitioners/" + practitioner.id}
          aria-label="open"
        >
          <VisibilityIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default PractitionersRow;
