// views
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import { Route, Routes, useNavigate } from "react-router-dom";
import { LoadingSpinner, Main, NotFoundView } from "common/components";
import React, { useEffect, useState } from "react";
import PractitionersView from "./3_PractitionersView/PractitionersView";
import PractitionerView from "./3_1_PractitionerView/PractitionerView";
import AppointmentsView from "./4_AppointmentsView/AppointmentsView";
import AccountView from "./9_AccountView/AccountView";
import HomeView from "./0_HomeView/HomeView";
import OrgView from "./6_OrgView/OrgView";
import AnnouncementsView from "./1_AnnouncementsView/AnnouncementsView";
import QuestionsView from "./2_QuestionsView/QuestionsView";
import MetricsView from "./5_MetricsView/MetricsView";

function AppUser() {
  const [isMember, setIsMember] = useState(false);
  const [user, loading, _error] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");

    user?.getIdTokenResult().then((idTokenResult) => {
      const isMember = idTokenResult.claims["member"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isAdmin = idTokenResult.claims["admin"];
      setIsMember(isMember);

      if (isMember) return;
      if (isPractitioner) navigate("/practitioner");
      if (isAdmin) navigate("/admin");
    });
  }, [user, loading, navigate]);

  if (!loading && isMember) {
    return (
      <Main>
        <Routes>
          <Route index element={<HomeView />} />
          <Route path="/announcements/" element={<AnnouncementsView />} />
          <Route path="/questions/" element={<QuestionsView />} />
          <Route path="/practitioners/" element={<PractitionersView />} />
          <Route path="/practitioners/:id" element={<PractitionerView />} />
          <Route path="/appointments/" element={<AppointmentsView />} />
          <Route path="/metrics/" element={<MetricsView />} />
          <Route path="/org/*" element={<OrgView />} />
          <Route path="/account/*" element={<AccountView />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </Main>
    );
  } else {
    return <LoadingSpinner />;
  }
}

export default AppUser;
