import React from "react";
import { Container } from "common/components";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useListMembersQuery } from "common/redux/features/FirestoreDB";
import MembersRow from "./components/MembersRow";
import CreateMemberFormDialog from "./components/CreateMemberFormDialog";
const headerNames = ["SUB", "Name", "Credits", "Email", "Actions"];

const MembersView = () => {
  const { data: members } = useListMembersQuery(undefined);

  return (
    <Container>
      <CreateMemberFormDialog />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerNames.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {members && members.map((member) => <MembersRow key={member.id} member={member} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default MembersView;
