import React from "react";
import { Container } from "common/components";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PractitionerAvailability from "./components/PractitionerAvailability";
import PractitionerDynamoSection from "./components/PractitionerDynamoSection";

function PractitionerView() {
  const { id } = useParams();
  const [disabled, setDisabled] = React.useState(true);
  const onEdit = () => {
    setDisabled((ps) => !ps);
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <PractitionerDynamoSection id={id ?? ""} disabled={disabled} onEdit={onEdit} />
        <PractitionerAvailability practitionerId={id ?? ""} />
      </Grid>
    </Container>
  );
}

export default PractitionerView;
