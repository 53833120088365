import React from "react";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContactDialog from "./components";

function ContactButton({
  children,
  endIcon,
  variant,
  display,
  justifyContent,
  marginTop,
  marginLeft,
  marginRight,
  fullWidth,
  size,
}: {
  children: string;
  endIcon: JSX.Element | undefined;
  variant: "text" | "outlined" | "contained" | undefined;
  display: string | undefined;
  justifyContent: string | undefined;
  marginTop: number;
  marginLeft: number;
  marginRight: number;
  fullWidth: boolean;
  size: "small" | "medium" | "large" | undefined;
}) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [dialogOpen, setDialogOpen] = React.useState(false);
  return (
    <>
      <Box
        marginTop={marginTop}
        marginLeft={marginLeft}
        marginRight={marginRight}
        display={display}
        justifyContent={justifyContent}
      >
        <Button
          variant={variant}
          color="primary"
          onClick={() => setDialogOpen((ps) => !ps)}
          size={size}
          fullWidth={fullWidth || !isMd ? true : false}
          endIcon={endIcon}
        >
          {children}
        </Button>
      </Box>
      <ContactDialog open={dialogOpen} handleClose={() => setDialogOpen(false)} />
    </>
  );
}

ContactButton.defaultProps = {
  endIcon: undefined,
  variant: "contained",
  display: undefined,
  justifyContent: undefined,
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  fullWidth: false,
  size: "small",
};

export default ContactButton;
