import React from "react";
import { Avatar, IconButton } from "@mui/material";
import { Practitioner } from "newAPI";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useTheme } from "@mui/material/styles";
import toast from "react-hot-toast";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, storage } from "firebase.config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useSetPractitionerMutation } from "common/redux/features/FirestoreDB";

function PractitionerAvatarUploader({
  practitioner,
  disabled,
}: {
  practitioner: Practitioner;
  disabled: boolean;
}) {
  const [user, _loading, _error] = useAuthState(auth);
  const theme = useTheme();
  const [setPractitioner] = useSetPractitionerMutation();

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const toastId = toast.loading("Actualizando...");
    try {
      if (!user) return;
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        // use practitioner.id for the file name
        const name = practitioner.id + file.type.split("/")[1];

        const imageRef = ref(storage, `avatars/${name}`);
        uploadBytes(imageRef, file).then(async (snapshot) => {
          const avatar = await getDownloadURL(snapshot.ref);
          setPractitioner({ id: practitioner.id, values: { avatar } });
        });
      }
      toast.success("Éxito!", { id: toastId });
    } catch (err) {
      toast.error("Error", { id: toastId });
    }
  };

  return (
    <>
      <input
        accept="image/*"
        type="file"
        hidden
        onChange={onFileChange}
        id="contained-button-file"
        disabled={disabled}
      />
      <label htmlFor="contained-button-file">
        <IconButton component="span" sx={{ padding: 0 }} disabled={disabled}>
          <Avatar
            src={practitioner.avatar ?? ""}
            variant={"circular"}
            sx={{
              width: {
                xs: theme.spacing(16),
                sm: theme.spacing(20),
              },
              height: {
                xs: theme.spacing(16),
                sm: theme.spacing(20),
              },
              border: `8px solid ${theme.palette.background.paper}`,
              opacity: disabled ? 0.5 : 1,
            }}
          />
          {!disabled && (
            <Avatar
              className="overlay"
              variant={"circular"}
              sx={{
                width: {
                  xs: theme.spacing(16),
                  sm: theme.spacing(20),
                },
                height: {
                  xs: theme.spacing(16),
                  sm: theme.spacing(20),
                },
                border: `8px solid ${theme.palette.background.paper}`,
                position: "absolute",
                opacity: 0,
                ":hover": { opacity: 0.5 },
              }}
            >
              <PhotoCameraIcon />
            </Avatar>
          )}
        </IconButton>
      </label>
    </>
  );
}

export default PractitionerAvatarUploader;
