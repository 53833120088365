import React from "react";
import { useLocation } from "react-router";

const ScrollToTop = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
