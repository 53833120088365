import React from "react";
import { Container } from "common/components";
import { Box, Grid } from "@mui/material";
import { useListPractitionersQuery } from "common/redux/features/FirestoreDB";
import PractitionersViewCard from "./components/PractitionersViewCard";

function PractitionersView() {
  const { data: practitioners } = useListPractitionersQuery([
    { field: "verified", op: "==", value: true },
    { field: "deleted", op: "==", value: false },
    { field: "disabled", op: "==", value: false },
  ]);

  return (
    <Box bgcolor={"primary.light"}>
      <Container mt={{ xs: 2, sm: 0 }}>
        <Grid container spacing={2}>
          {practitioners?.map(
            (practitioner) =>
              practitioner !== null && (
                <PractitionersViewCard key={practitioner.id} practitioner={practitioner} />
              )
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default PractitionersView;
