import React from "react";
import { Container } from "common/components";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import Settings from "@mui/icons-material/Psychology";
import {
  useGetPlanQuery,
  useListCompaniesQuery,
  useSetPlanMutation,
} from "common/redux/features/FirestoreDB";
import CompaniesRow from "../CompaniesView/components/CompaniesRow";

// This is for the companies' table
const headerNames = ["ID", "Name", "Updated At", "Plan", "Plan Credits", "Actions"];

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Please enter a valid plan name")
    .max(50, "Please enter a valid plan name")
    .required("Please specify the plan's name"),
  credits: yup
    .number()
    .integer()
    .moreThan(-1, "Please specify a valid credits configuration (>=0)")
    .required("Please specify the credits configuration"),
});

function PlanView() {
  const { t } = useTranslation("common");
  const { id } = useParams();

  const { data: plan } = useGetPlanQuery(id ?? "");
  const { data: companies } = useListCompaniesQuery([{ field: "planId", op: "==", value: id }]);

  const [disabled, setDisabled] = React.useState(true);
  const initialValues = {
    name: plan?.name ?? "",
    board: plan?.board ?? false,
    questions: plan?.questions ?? false,
    indexes: plan?.indexes ?? false,
    credits: plan?.credits ?? 0,
  };

  const onEdit = () => {
    setDisabled((ps) => !ps);
  };

  const [setPlan] = useSetPlanMutation();

  const onSubmit = async (values: {
    name: string;
    board: boolean;
    questions: boolean;
    indexes: boolean;
    credits: number;
  }) => {
    if (plan && plan.id) {
      toast.promise(setPlan({ id: plan.id, values }), {
        loading: t("common.toast.saving"),
        success: () => {
          onEdit();
          return t("common.toast.planUpdatedSuccessfully"); // TODO replace
        },
        error: t("common.toast.error"),
      });
    } else {
      toast("User doesn't exist in DB. In needs to be created manually!", {
        icon: <Settings />,
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>
              {t("admin.plans.plan")}
              {id}
            </h1>
            <Box
              sx={{
                flexDirection: "row-reverse",
              }}
            >
              {disabled && (
                <Button variant="contained" onClick={onEdit}>
                  {t("common.buttons.edit")}
                </Button>
              )}
              {!disabled && (
                <ButtonGroup variant="contained">
                  <Button variant="contained" onClick={() => formik.handleSubmit()}>
                    {t("common.buttons.accept")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.resetForm();
                      onEdit();
                    }}
                  >
                    {t("common.buttons.cancel")}
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            disabled={disabled}
            label={t("admin.common.labels.name")}
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  name="board"
                  checked={formik.values.board}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={`${t("admin.common.labels.board")} *`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  name="questions"
                  checked={formik.values.questions}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={`${t("admin.common.labels.questions")} *`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  name="indexes"
                  checked={formik.values.indexes}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={`${t("admin.common.labels.indexes")} *`}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            disabled={disabled}
            label={t("admin.common.labels.credits")}
            fullWidth
            name="credits"
            type="number"
            value={formik.values.credits}
            onChange={formik.handleChange}
            error={formik.touched.credits && Boolean(formik.errors.credits)}
            helperText={formik.touched.credits && formik.errors.credits}
          />
        </Grid>
        <Divider />
        <h2>{t("admin.plans.companiesInPlan")}</h2>
        <Table>
          <TableHead>
            <TableRow>
              {headerNames.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {companies !== undefined &&
              companies !== null &&
              companies.map(
                (company) => company && <CompaniesRow key={company?.id} company={company} />
              )}
          </TableBody>
        </Table>
      </Grid>
    </Container>
  );
}

export default PlanView;
