import React from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { Company } from "newAPI";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link as RouterLink } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useGetPlanQuery } from "common/redux/features/FirestoreDB";

function CompaniesRow({ company }: { company: Company }) {
  const { data: plan } = useGetPlanQuery(company.planId ?? "");

  return (
    <TableRow hover key={company.name}>
      <TableCell>{company.id}</TableCell>
      <TableCell>{company.name}</TableCell>
      <TableCell>{plan?.name}</TableCell>
      <TableCell>{plan?.board ? <DoneIcon /> : <ClearIcon />}</TableCell>
      <TableCell>{plan?.questions ? <DoneIcon /> : <ClearIcon />}</TableCell>
      <TableCell>{plan?.indexes ? <DoneIcon /> : <ClearIcon />}</TableCell>
      <TableCell>{plan?.credits}</TableCell>
      <TableCell>
        <IconButton component={RouterLink} to={"/admin/companies/" + company.id}>
          <VisibilityIcon />
        </IconButton>
        {/* TODO delete company function */}
        <IconButton onClick={() => undefined} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default CompaniesRow;
