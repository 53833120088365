import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, Card, Grid, List, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Container } from "common/components";
import { MenuContext } from "common/context/MenuContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";

function AccountPage({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const menu = React.useContext(MenuContext);
  const [user, _loading, _error] = useAuthState(auth);

  // set groups
  const [groups, setGroups] = React.useState([] as Array<string>);
  useEffect(() => {
    user?.getIdTokenResult().then((idTokenResult) => {
      const isMember = idTokenResult.claims["member"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isManager = idTokenResult.claims["manager"];
      const isAdmin = idTokenResult.claims["admin"];

      const localGroups = [] as Array<string>;
      if (isMember) localGroups.push("Members");
      if (isPractitioner) localGroups.push("Practitioners");
      if (isManager) localGroups.push("Managers");
      if (isAdmin) localGroups.push("Admins");

      setGroups(localGroups);
    });
  }, [user]);

  return (
    <>
      <Box bgcolor={"primary.main"} paddingY={4}>
        <Container mt={{ xs: 2, sm: 0 }}>
          <Typography variant="h4" fontWeight={700} gutterBottom sx={{ color: "common.white" }}>
            {t("common.account.header")}
          </Typography>
          <Typography variant="h6" sx={{ color: "common.white" }}>
            {t("common.account.body")}
          </Typography>
        </Container>
      </Box>
      <Container paddingTop={"0 !important"} marginTop={-8}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Card sx={{ boxShadow: 3 }}>
              <List
                disablePadding
                sx={{
                  display: {
                    xs: "inline-flex",
                    md: "flex",
                  },
                  flexDirection: {
                    xs: "row",
                    md: "column",
                  },
                  overflow: "auto",
                  flexWrap: "nowrap",
                  width: "100%",
                  paddingY: {
                    xs: 3,
                    md: 4,
                  },
                  paddingX: {
                    xs: 4,
                    md: 0,
                  },
                }}
              >
                {menu.accountTabs.map(
                  (item) =>
                    ((!groups && !item.group) || (item.group && groups?.includes(item.group))) && (
                      <ListItem
                        key={item.id}
                        component={RouterLink}
                        to={item.to}
                        disableGutters
                        sx={{
                          marginRight: {
                            xs: 2,
                            md: 0,
                          },
                          flex: 0,
                          paddingX: {
                            xs: 0,
                            md: 3,
                          },
                          borderLeft: {
                            xs: "none",
                            md: "2px solid transparent",
                          },
                          borderLeftColor: {
                            md:
                              item.to === window?.location?.pathname
                                ? theme.palette.primary.main
                                : "transparent",
                          },
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          noWrap
                          color={
                            item.to === window?.location?.pathname
                              ? "text.primary"
                              : "text.secondary"
                          }
                        >
                          {t(item.id)}
                        </Typography>
                      </ListItem>
                    )
                )}
              </List>
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card
              sx={{
                boxShadow: 3,
                padding: 4,
              }}
            >
              {children}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AccountPage;
