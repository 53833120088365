import React, { useState } from "react";
// layout
import { Container } from "common/components";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Calendar } from "newAPI";
import moment from "moment";
import { auth } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useListAppointmentsQuery } from "common/redux/features/FirestoreDB";
import AppointmentsViewRow from "./components/AppointmentsViewRow";
// import { lodash } from "lodash";

function AppointmentsView() {
  const { t } = useTranslation("common");
  const [user, _loading, _error] = useAuthState(auth);
  const [calendar, setCalendar] = useState({} as Calendar);

  const { data: appointments } = useListAppointmentsQuery([
    { field: "memberId", op: "==", value: user?.uid },
  ]);

  React.useLayoutEffect(() => {
    // sort appointments
    const sorted =
      appointments?.slice().sort((a, b) => {
        if (a.datetime > b.datetime) {
          return -1;
        }
        if (a.datetime < b.datetime) {
          return 1;
        }
        return 0;
      }) ?? [];
    // transform them to a calendar
    const cal = {} as Calendar;
    for (const appointment of sorted) {
      const yearMonth = moment(appointment.datetime).format("YYYY - MMMM");
      if (!cal[yearMonth]) {
        cal[yearMonth] = [];
      }
      cal[yearMonth].push(appointment);
    }
    setCalendar(cal);
  }, [appointments, setCalendar]);

  return (
    <Box bgcolor={"primary.light"}>
      <Container mt={{ xs: 4, sm: 2 }}>
        {Object.entries(calendar).length == 0 && <Typography my={2}>No hay citas.</Typography>}
        {Object.entries(calendar).map(([yearMonth, events]) => (
          // the following line changes the margin-bottom param for all containers except for the last one
          <Box key={yearMonth}>
            <Typography my={2} variant="h5" fontWeight={500}>
              {yearMonth}
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }} aria-label="simple table">
                <TableHead sx={{ bgcolor: "primary.main" }}>
                  <TableRow>
                    {(
                      t("member.appointmentsView.tableHeaders", {
                        returnObjects: true,
                      }) as Array<string>
                    ).map((item) => (
                      <TableCell key={item}>
                        <Typography
                          color={"primary.contrastText"}
                          variant={"caption"}
                          fontWeight={700}
                          sx={{
                            textTransform: "uppercase",
                          }}
                        >
                          {item}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events &&
                    events?.map(
                      (appointment) =>
                        appointment !== null && (
                          <AppointmentsViewRow appointment={appointment} key={appointment.id} />
                        )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Container>
    </Box>
  );
}

export default AppointmentsView;
