import React from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useFormik } from "formik";
import { Box, Button, Divider, Grid, Link, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import { sendEmailVerification, updateEmail } from "firebase/auth";
import {
  useGetPractitionerQuery,
  useSetPractitionerMutation,
} from "common/redux/features/FirestoreDB";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
});

function AttributeName() {
  const { t } = useTranslation("common");
  const [user, _loading, _error] = useAuthState(auth);

  const { data: practitioner } = useGetPractitionerQuery(user?.uid ?? "");
  const [setPractitioner] = useSetPractitionerMutation();

  const initialValues = {
    name: practitioner?.name ?? "",
    email: user?.email ?? "",
  };

  const onSubmit = (values: { name: string; email: string }) => {
    if (!user) return;
    if (values.email != user?.email || values.name != practitioner?.name) {
      updateEmail(user, values.email).then(() => {
        toast.promise(setPractitioner({ id: user.uid, values }), {
          loading: t("common.toast.updating"),
          success: t("common.toast.nameUpdatedSuccessfully"),
          error: t("common.toast.error"),
        });
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <>
      <Typography variant="h6" gutterBottom fontWeight={700}>
        {t("common.account.general.header")}
      </Typography>
      <Typography variant={"subtitle2"} color={"text.secondary"}>
        {t("common.account.general.body1")}
        <Link color={"primary"} component={RouterLink} to={"/privacy"} underline={"none"}>
          {t("common.account.general.body2")}
        </Link>{" "}
        {t("common.account.general.body3")}
      </Typography>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
              {t("common.account.general.email")}
            </Typography>
            <TextField
              label={t("common.account.general.email")}
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          {!user?.emailVerified && (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant={"subtitle2"} color={"text.secondary"}>
                  {t("common.account.general.unconfirmedEmailAddress")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  flexDirection={{
                    xs: "column",
                    sm: "row-reverse",
                  }}
                  alignItems={{
                    xs: "stretched",
                    sm: "center",
                  }}
                  justifyContent={"space-between"}
                  width={1}
                >
                  <Button
                    size={"large"}
                    variant={"contained"}
                    onClick={() => {
                      if (!user) return;
                      toast.promise(sendEmailVerification(user), {
                        loading: "Enviando...",
                        success: "Enlace enviado con éxtio",
                        error: "Error al enviar el enlace",
                      });
                    }}
                  >
                    Enviar enlace de verificación
                  </Button>
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }} fontWeight={700}>
              {t("common.account.general.name")}
            </Typography>
            <TextField
              label={`${t("common.account.general.name")} *`}
              variant="outlined"
              name={"name"}
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{
                xs: "column",
                sm: "row-reverse",
              }}
              alignItems={{
                xs: "stretched",
                sm: "center",
              }}
              justifyContent={"space-between"}
              width={1}
            >
              <Button size={"large"} variant={"contained"} type={"submit"}>
                {t("common.buttons.save")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default AttributeName;
