import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useAddQuestionMutation, useGetMemberQuery } from "common/redux/features/FirestoreDB";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";

const validationSchema = yup.object({
  title: yup
    .string()
    .trim()
    .min(2, "Please enter a valid title")
    .max(50, "Please enter a valid title")
    .required("Please specify the user's title"),
  description: yup
    .string()
    .trim()
    .min(2, "Please enter a valid description")
    .max(50, "Please enter a valid description")
    .required("Please specify the user's description"),
  options: yup.array().of(yup.string()),
});

const CreateQuestionFormDialog = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  const [user, _loading, _error] = useAuthState(auth);
  const { data: member } = useGetMemberQuery(user?.uid ?? "");

  const [open, setOpen] = useState(false);
  const initialValues = {
    title: "",
    description: "",
    options: [] as Array<string>,
  };

  const [addQuestion] = useAddQuestionMutation();

  const onSubmit = async (values: {
    title: string;
    description: string;
    options: Array<string>;
  }) => {
    console.log("values", values);
    toast.promise(addQuestion({ ...values, status: "DRAFT", companyId: member?.companyId }), {
      loading: t("common.toast.creating"),
      success: () => {
        setOpen(false);
        return t("common.toast.questionCreatedSuccessfully");
      },
      error: t("common.toast.error"),
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      display="flex"
      flexDirection={{
        xs: "column",
        sm: "row-reverse",
      }}
      alignItems={{
        xs: "stretched",
        sm: "center",
      }}
      justifyContent={"space-between"}
      width={1}
    >
      <Box sx={{ width: !isSm ? "auto" : 1 }}>
        <Button variant="contained" onClick={handleClickOpen} fullWidth={isMd}>
          {t("admin.members.createQuestion")}
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>{t("admin.members.createQuestion")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("admin.members.dialog")}</DialogContentText>
            <TextField
              autoFocus
              name="title"
              label={`${t("admin.common.labels.title")} *`}
              type="text"
              fullWidth
              variant="standard"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <TextField
              name="description"
              label={`${t("admin.common.labels.description")} *`}
              type="text"
              fullWidth
              variant="standard"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
            <Box mt={2}>
              <Typography variant="overline" fontWeight={200}>
                Opciones
              </Typography>
              <Box ml={3}>
                {formik.values.options.map((item, index) => {
                  return (
                    <TextField
                      key={index}
                      name={`options[${index}]`}
                      fullWidth
                      variant="standard"
                      value={item}
                      margin="dense"
                      onChange={formik.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                const newOptions = [...formik.values.options];
                                newOptions.splice(index, 1);
                                console.log("index", index);
                                console.log("newOptions", newOptions);
                                formik.setFieldValue("options", newOptions);
                                console.log("formik.values.options", formik.values.options);
                              }}
                              edge="end"
                            >
                              <CancelIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                })}
              </Box>
              <Button
                onClick={() => formik.setFieldValue("options", formik.values.options.concat([""]))}
              >
                Add option
              </Button>
            </Box>
            {/* <Button onClick={() => formik.values.options.push("")}>Add option</Button> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
            <Button type="submit">{t("common.buttons.create")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default CreateQuestionFormDialog;
