import React from "react";
import { Container } from "common/components";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useListMembersQuery } from "common/redux/features/FirestoreDB";

const headerNames = ["SUB", "Email"];

function ManagersView() {
  const { t } = useTranslation("common");

  const { data: managers } = useListMembersQuery([{ field: "isManager", op: "==", value: true }]);

  return (
    <Container>
      <h1> {t("admin.managers.header")}</h1>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerNames.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {managers !== undefined &&
              managers !== null &&
              managers.map(
                (manager) =>
                  manager && (
                    <TableRow hover key={manager.id}>
                      <TableCell>{manager.name}</TableCell>
                      <TableCell>{manager.email}</TableCell>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default ManagersView;
