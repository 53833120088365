import React from "react";
import Box from "@mui/material/Box";
import logo from "common/img/logo/logo.svg";
import { Link as RouterLink } from "react-router-dom";

function Logo({ xs, md, onClose }: { xs: number; md: number; onClose: () => void }) {
  return (
    <Box
      display={"flex"}
      component={RouterLink}
      to="/"
      onClick={onClose}
      title="theFront"
      width={{ xs: xs, md: md }}
    >
      <Box component={"img"} src={logo} height={1} width={1} />
    </Box>
  );
}
Logo.defaultProps = {
  xs: 100,
  md: 120,
  onClose: () => undefined,
};

export default Logo;
