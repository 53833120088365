import React from "react";
import ReactDOM from "react-dom/client";

// Redux
import { Provider } from "react-redux";
import { store } from "common/redux/store";

// internationalisation
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import esTranslation from "common/translations/es.json";

// MenuContext
import { MenuContext } from "common/context/MenuContext";

// Google Tag Manager
import TagManager from "react-gtm-module";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "./theme";
import { menu } from "./navigation";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "es", // language to use
  resources: {
    es: {
      common: esTranslation,
    },
  },
  // debug: true,
});

const tagManagerArgs = {
  gtmId: "GTM-PKKSR6M",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <ThemeProvider>
        <Provider store={store}>
          <MenuContext.Provider value={menu}>
            <App />
          </MenuContext.Provider>
        </Provider>
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
