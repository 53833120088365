import React, { useEffect, useState } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";

import { LoadingSpinner, Main, NotFoundView } from "common/components";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import ProfileView from "./ProfileView/ProfileView";
import AccountView from "./AccountView/AccountView";
import AppointmentsView from "./AppointmentsView/AppointmentsView";
import HomeView from "./HomeView/HomeView";

function AppPractitioner() {
  const [isPractitioner, setIsPractitioner] = useState(false);
  const [user, loading, _error] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");

    user?.getIdTokenResult().then((idTokenResult) => {
      const isMember = idTokenResult.claims["member"];
      const isPractitioner = idTokenResult.claims["practitioner"];
      const isAdmin = idTokenResult.claims["admin"];
      setIsPractitioner(isPractitioner);

      if (isPractitioner) return;
      if (isMember) navigate("/user");
      if (isAdmin) navigate("/admin");
    });
  }, [user, navigate]);

  if (!loading && isPractitioner) {
    return (
      <Main>
        <Routes>
          <Route index element={<HomeView />} />
          <Route path="/appointments/" element={<AppointmentsView />} />
          <Route path="/profile/" element={<ProfileView />} />
          <Route path="/account/*" element={<AccountView />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </Main>
    );
  } else {
    return <LoadingSpinner />;
  }
}

export default AppPractitioner;
