import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { auth, signInWithGoogle } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Logo from "common/components/Main/components/common/Logo";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-hot-toast";

function Login() {
  const { t } = useTranslation("common");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, _loading, _error] = useAuthState(auth);
  const navigate = useNavigate();

  const [loginLoading, setLoginLoading] = React.useState(false);
  const [loginWithGoogleLoading, setLoginWithGoogleLoading] = React.useState(false);

  async function login() {
    if (!loginLoading) {
      setLoginLoading(true);
      await signInWithEmailAndPassword(auth, email, password)
        .then(() => navigate("/user"))
        .then(() => setLoginLoading(false))
        .catch(() => {
          toast.error("Error");
          setLoginLoading(false);
        });
    }
  }

  async function loginWithGoogle() {
    if (!loginWithGoogleLoading) {
      setLoginWithGoogleLoading(true);
      await signInWithGoogle()
        .then(() => navigate("/user"))
        .then(() => setLoginWithGoogleLoading(false))
        .catch(() => {
          toast.error("Error");
          setLoginWithGoogleLoading(false);
        });
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Logo xs={300} md={500} />
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <TextField
            autoFocus
            type="text"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Correo electrónico"
            margin="normal"
          />
          <TextField
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Contraseña"
            margin="normal"
          />
        </CardContent>
        <CardActions sx={{ mt: -2, mx: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" disabled={loginLoading} onClick={login}>
                {loginLoading && (
                  <CircularProgress
                    color="inherit"
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
                Log in
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                disabled={loginWithGoogleLoading}
                onClick={loginWithGoogle}
              >
                {loginWithGoogleLoading && (
                  <CircularProgress
                    color="inherit"
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
                Log in with Google
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth component={RouterLink} to={"/reset"}>
                ¿Olvidó su contraseña?
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <Typography mt={4} variant="body2">
        © 2022-2023 aita.care. Todos los derechos reservados
      </Typography>
    </Grid>
  );
}
export default Login;
