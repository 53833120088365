import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TableCell,
  Typography,
} from "@mui/material";
import { Appointment } from "newAPI";
import { useGetMemberQuery } from "common/redux/features/FirestoreDB";

function MemberAvatarAndCredits({ appointment }: { appointment: Appointment }) {
  const { data: member } = useGetMemberQuery(appointment.memberId ?? "");

  return (
    <>
      <TableCell component="th" scope="row">
        <List sx={{ p: 0, m: 0 }}>
          <ListItem sx={{ p: 0, m: 0 }}>
            <ListItemAvatar>
              <Avatar src={member?.avatar ?? ""} />
            </ListItemAvatar>
            <ListItemText primary={member?.name} secondary={member?.email} />
          </ListItem>
        </List>
      </TableCell>
      <TableCell>
        <Typography>{member?.credits}</Typography>
      </TableCell>
    </>
  );
}

export default MemberAvatarAndCredits;
