import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

function NavIcon({ onSideBarOpen }: { onSideBarOpen: () => void }) {
  const theme = useTheme();

  return (
    <Box sx={{ display: { xs: "flex", md: "none" } }} alignItems={"center"}>
      <Button
        onClick={onSideBarOpen}
        aria-label="Menu"
        variant={"outlined"}
        sx={{
          borderRadius: 2,
          minWidth: "auto",
          padding: 1,
          borderColor: alpha(theme.palette.divider, 0.2),
        }}
      >
        <MenuIcon />
      </Button>
    </Box>
  );
}

export default NavIcon;
