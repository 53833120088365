import React, { useEffect, useState } from "react";
import { Container } from "common/components";
import { useTranslation } from "react-i18next";
import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { auth } from "firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useGetMemberQuery } from "common/redux/features/FirestoreDB";

function HomeView() {
  const [user, _loading, _error] = useAuthState(auth);
  const [creditsLine, setCreditsLine] = useState("");
  const [isManager, setIsManager] = useState(false);
  const { t } = useTranslation("common");

  const { data: member } = useGetMemberQuery(user?.uid ?? "");

  // if there's a user, check if its a manager or not
  useEffect(() => {
    user?.getIdTokenResult().then((idTokenResult) => {
      const isManager = idTokenResult.claims["manager"];
      setIsManager(isManager);
    });
  }, [user]);

  // set the credits line
  useEffect(() => {
    if (member?.credits) {
      if (member?.credits > 1) {
        setCreditsLine(
          t("member.homeView.creditsLineMany", {
            credits: member?.credits,
          })
        );
      } else if (member?.credits == 1) {
        setCreditsLine(
          t("member.homeView.creditsLineOne", {
            credits: member?.credits,
          })
        );
      } else {
        setCreditsLine(t("member.homeView.creditsLineNone"));
      }
    }
  }, [member, t]);

  return (
    <Container mt={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={200}>
            {t("member.homeView.firstLine", {
              name: member?.name,
            })}
          </Typography>
          <Typography mt={3} fontWeight={200}>
            {creditsLine}
          </Typography>
          <Typography mt={3} fontWeight={200}>
            {t("member.homeView.question")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                {t("member.homeView.seeAnnouncements")}
              </Typography>
              <Typography variant="body2">{t("member.homeView.whatIsSeeAnnouncements")}</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={RouterLink} to={"/user/announcements"}>
                {t("member.homeView.goSeeAnnouncements")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                {t("member.homeView.seeQuestions")}
              </Typography>
              <Typography variant="body2">{t("member.homeView.whatIsSeeQuestions")}</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={RouterLink} to={"/user/questions"}>
                {t("member.homeView.goSeeQuestions")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                {t("member.homeView.seePractitioners")}
              </Typography>
              <Typography variant="body2">{t("member.homeView.whatIsSeePractitioners")}</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={RouterLink} to={"/user/practitioners"}>
                {t("member.homeView.goSeePractitioners")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div">
                {t("member.homeView.seeAppointments")}
              </Typography>
              <Typography variant="body2">{t("member.homeView.whatIsSeeAppointments")}</Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={RouterLink} to={"/user/appointments"}>
                {t("member.homeView.goSeeAppointments")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {isManager && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" component="div">
                    {t("member.homeView.metrics")}
                  </Typography>
                  <Typography variant="body2">{t("member.homeView.whatIsMetrics")}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={RouterLink} to={"/user/metrics"}>
                    {t("member.homeView.goMetrics")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" component="div">
                    {t("member.homeView.manageOrg")}
                  </Typography>
                  <Typography variant="body2">{t("member.homeView.whatIsManageOrg")}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={RouterLink} to={"/user/org"}>
                    {t("member.homeView.goManageOrg")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}

export default HomeView;
