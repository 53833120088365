import React from "react";
import ReactApexChart from "react-apexcharts";
// @mui
import { Box, Card, CardHeader } from "@mui/material";
// components
import useChart from "common/components/Chart";

// ----------------------------------------------------------------------

export default function AppWebsiteVisits({
  title,
  subheader,
  chartLabels,
  chartData,
  ...other
}: {
  title: string;
  subheader: string;
  chartData: { name: string; type: string; fill: string; data: number[] }[];
  chartLabels: string[];
}) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "16%" } },
    // fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      // y: {
      //   formatter: (y) => {
      //     if (typeof y !== 'undefined') {
      //       return `${y.toFixed(0)} visits`;
      //     }
      //     return y;
      //   },
      // },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
