import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CreateQuestionFormDialog from "./components/CreateQuestionFormDialog";
import { useGetMemberQuery, useListQuestionsQuery } from "common/redux/features/FirestoreDB";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.config";
import QuestionRow from "./components/QuestionRow";
import { useTranslation } from "react-i18next";

function QuestionsView() {
  const { t } = useTranslation("common");
  const [user, _loading, _error] = useAuthState(auth);
  const { data: member } = useGetMemberQuery(user?.uid ?? "");

  const [skip, setSkip] = useState(true);
  useEffect(() => {
    if (member) setSkip(false);
  }, [member]);

  const { data: questions } = useListQuestionsQuery(
    [{ field: "companyId", op: "==", value: member?.companyId }],
    { skip }
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" fontWeight={500}>
          Gestión de preguntas
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CreateQuestionFormDialog />
      </Grid>
      {(!questions || questions.length == 0) && (
        <Grid item container spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography>No hay preguntas.</Typography>
          </Grid>
        </Grid>
      )}
      {questions && questions.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-label="simple table">
              <TableHead sx={{ bgcolor: "primary.main" }}>
                <TableRow>
                  {(
                    t("member.orgView.questionstableHeaders", {
                      returnObjects: true,
                    }) as Array<string>
                  ).map((item) => (
                    <TableCell key={item}>
                      <Typography
                        color={"primary.light"}
                        variant={"caption"}
                        fontWeight={700}
                        sx={{
                          textTransform: "uppercase",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {questions.map(
                  (question) =>
                    question !== null &&
                    !question.deleted && <QuestionRow question={question} key={question.id} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
}

export default QuestionsView;
