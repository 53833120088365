import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Appointment } from "newAPI";
import toast from "react-hot-toast";
import moment from "moment";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import AppointmentsViewPractitionerAvatar from "./AppointmentsViewPractitionerAvatar";
import { useMemberCancelAppointmentMutation } from "common/redux/features/FirestoreDB";

function AppointmentsViewCard({ appointment }: { appointment: Appointment }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation("common");
  const theme = useTheme();

  const [memberCancelAppointment] = useMemberCancelAppointmentMutation()

  async function cancelAppointmentAction(id: string) {
    toast.promise(
      memberCancelAppointment({
        appointmentId: id,
      }),
      {
        loading: t("common.toast.cancelling"),
        success: t("common.toast.cancelledSuccessfully"),
        error: t("common.toast.error"),
      }
    );
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <TableRow
      hover
      key={appointment.id}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row" sx={{ pt: 1, pb: 1 }}>
        <AppointmentsViewPractitionerAvatar id={appointment.practitionerId ?? ""} />
      </TableCell>
      <TableCell>
        <Typography>
          <Moment format="LLLL">{appointment.datetime}</Moment>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant={"caption"}
          fontWeight={700}
          sx={{
            bgcolor: alpha(
              appointment.status === "RESERVADA"
                ? theme.palette.success.light
                : appointment.status === "CANCELADA TARDE"
                  ? theme.palette.error.light
                  : theme.palette.warning.light,
              0.1
            ),
            color:
              appointment.status === "RESERVADA"
                ? theme.palette.success.dark
                : appointment.status === "CANCELADA TARDE"
                  ? theme.palette.error.dark
                  : theme.palette.warning.dark,
            paddingX: 1.5,
            paddingY: 0.5,
            borderRadius: 4,
            display: "inline",
          }}
        >
          {appointment.status}
        </Typography>
      </TableCell>
      <TableCell>
        {moment(appointment.datetime).isAfter(moment()) &&
          !appointment.status?.startsWith("CANCELADA") && (
          <Tooltip title="Cancel appointment">
            <IconButton aria-label="cancel appointment" onClick={handleOpen}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t("member.appointmentsView.cancelAppointment.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("member.appointmentsView.cancelAppointment.body", {
              date: moment(appointment.datetime).format("dddd D"),
              month: moment(appointment.datetime).format("MMMM"),
              time: moment(appointment.datetime).format("HH:mm"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("common.buttons.cancel")}</Button>
          <Button
            onClick={() => {
              cancelAppointmentAction(appointment.id);
              handleClose();
            }}
            autoFocus
          >
            {t("common.buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}

export default AppointmentsViewCard;
