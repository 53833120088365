import React from "react";
import { Avatar, Box, Card, Chip, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Practitioner } from "newAPI";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import PractitionerAvailability from "./PractitionerAvailability";

function ViewProfile({
  practitioner,
  openEditView,
}: {
  practitioner: Practitioner;
  openEditView: () => void;
}) {
  const { t } = useTranslation("common");
  const theme = useTheme();

  return (
    <>
      <Box zIndex={1000}>
        <Avatar
          src={practitioner.avatar ?? ""}
          variant={"circular"}
          sx={{
            width: {
              xs: theme.spacing(16),
              sm: theme.spacing(20),
            },
            height: {
              xs: theme.spacing(16),
              sm: theme.spacing(20),
            },
            border: `8px solid ${theme.palette.background.paper}`,
          }}
        />
      </Box>
      <Card
        sx={{
          p: { xs: 2, md: 4 },
          marginTop: theme.spacing(-10),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 1,
          height: 1,
        }}
      >
        <IconButton
          sx={{
            alignSelf: "flex-end",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.divider,
          }}
          onClick={openEditView}
        >
          <EditIcon />
        </IconButton>
        <Box marginTop={2} sx={{ width: "100%" }}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={2}>
            <Typography fontWeight={700} variant={"h4"}>
              {practitioner.name}
            </Typography>
          </Box>
          <Typography color={"text.secondary"} variant={"h6"} align={"center"}>
            {practitioner.title}
          </Typography>
          <Stack spacing={2} marginTop={4} width={1} alignItems={"center"}>
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
              spacing={{ xs: 1, md: 2 }}
            >
              {practitioner.specialities?.map((speciality) => (
                <Chip key={speciality} label={speciality} color="primary" variant="outlined" />
              ))}
            </Stack>

            <Box sx={{ width: "100%" }}>
              <Typography variant="h5" fontWeight={700}>
                {t("practitioner.profile.summary")}
              </Typography>
              <Typography variant="body1" align="justify">
                {practitioner.summary}
              </Typography>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Typography variant="h5" fontWeight={700}>
                {t("practitioner.profile.description")}
              </Typography>
              <Typography
                variant="body1"
                align="justify"
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {practitioner.description}
              </Typography>
            </Box>

            <PractitionerAvailability />
          </Stack>
        </Box>
      </Card>
    </>
  );
}

export default ViewProfile;
