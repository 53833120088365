// Import the functions you need from the SDKs you need
import { FirebaseError, initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
  signOut,
  updatePassword,
} from "firebase/auth";
import { connectFirestoreEmulator, doc, getFirestore, setDoc } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { firebaseConfig as devConfig } from "./firebase.config.dev";
import { firebaseConfig as prodConfig } from "./firebase.config.prod";

// Your web app's Firebase configuration
const isDevEnvironment = process.env.REACT_APP_FIREBASE_ENV === "development";
console.log("process.env.REACT_APP_FIREBASE_ENV", process.env.REACT_APP_FIREBASE_ENV);
console.log("isDevEnvironment", isDevEnvironment);
export const firebaseConfig = isDevEnvironment ? devConfig : prodConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialise services
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);

if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("https://www.googleapis.com/auth/calendar");
googleProvider.setCustomParameters({
  access_type: "offline",
  prompt: "consent",
});

const signInWithGoogle = async () => {
  await signInWithPopup(auth, googleProvider);
};

const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, "members", user.uid), {
      name,
      authProvider: "local",
      email,
      avatar: "",
      companyId: "",
      planId: "",
    });
  } catch (err) {
    // console.error(err);
    if (err instanceof FirebaseError) {
      alert(err.message);
    }
  }
};

const logout = async () => {
  await signOut(auth);
};

//////////////////////////////////////////////////////////////
// Exports

export {
  auth,
  updatePassword,
  db,
  signInWithGoogle,
  registerWithEmailAndPassword,
  logout,
  // functions
  functions,
  // storage
  storage,
};
