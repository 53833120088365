import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetPractitionerQuery } from "common/redux/features/FirestoreDB";

function AppointmentsViewPractitionerAvatar({ id }: { id: string }) {
  const { t } = useTranslation("common");

  const { data: practitioner } = useGetPractitionerQuery(id ?? "");

  return (
    <List sx={{ p: 0, m: 0 }}>
      <Tooltip title={t("member.appointmentsView.seePractitionerTooltip")}>
        <ListItemButton component={RouterLink} to={"/user/practitioners/" + id} sx={{ p: 0, m: 0 }}>
          <ListItem sx={{ p: 0, m: 0 }}>
            <ListItemAvatar>
              <Avatar src={practitioner?.avatar ?? ""} />
            </ListItemAvatar>
            <ListItemText primary={practitioner?.name} secondary={practitioner?.title} />
          </ListItem>
        </ListItemButton>
      </Tooltip>
    </List>
  );
}

export default AppointmentsViewPractitionerAvatar;
